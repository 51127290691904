/** @format */

import React from "react";
import { Dropdown, SplitButton } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LogoutTheUser } from "../../Auth/authSlice";
export const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    dispatch(LogoutTheUser());
    navigate("/admin-login");
  };
  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand navbar-light thenavbar">
          <NavLink
            className={
              props.sidebarFull ? "navbar-brand logo_brand" : "navbar-brand"
            }
            to="/"
          >
            <img className="logo" src="/images/logo.png" alt="Logo" />
            <img
              className="logo_full"
              src="/images/book-my-car-logo-v1.png"
              alt="Logo"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* <ul className="list-unstyled expander_icon d-lg-none d-block">
            <li>
              <NavLink className="expander gray_blue" to="#" title="Expander">
                <span>
                  <img className="gray_blue" src="images/Expander.svg" alt="" />
                </span>
              </NavLink>
            </li>
          </ul> */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div aria-label="breadcrumb" className="mr-auto">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/admin-dashboard">
                    <img className="gray_blue" src="/images/home.svg" alt="" />
                  </NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  ADMIN DASHBOARD
                </li>
              </ol>
            </div>
            <ul className="navbar-nav my-2 my-lg-0">
              <li className="nav-item">
                <SplitButton
                  id="navbarDropdown"
                  variant="light"
                  title={
                    <>
                      <span className="profile_pic">
                        <img src="/images/user.png" alt="User" />
                      </span>
                      <span className="themeBlueColor"> Admin </span>
                    </>
                  }
                >
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </SplitButton>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};
