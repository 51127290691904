/** @format */

import { NavLink } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { MdImageNotSupported } from "react-icons/md";
import adminService from "../../services/adminService";
import { ReactToast, ReactToastSuccess } from "../../blocks/Modals/ReactToast";
import { BorderSpinner } from "../../blocks/Loaders/BorderSpinner";

export const AddCar = () => {
  const [myImages, setMyImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);
  const formikRef = useRef();
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  let getQuery = (key) => {
    return new URL(window.location.href).searchParams.get(key);
  };

  let car_features;
  try {
    car_features = JSON.parse(getQuery('car_features'));
  } catch (error) {
    console.error('Error parsing car_features JSON:', error);
    car_features = [];
  }

  const ProfileSchema = Yup.object().shape({
    car_type: Yup.string().required("Please Enter Car Type"),
    our_fee: Yup.string().required("Please Enter Car Price/Mile"),
    dispatcher_fee: Yup.string().required("Please Enter Dispatcher Price/Mile"),
    passenger: Yup.string().required("Please Enter Number Of Passengers"),
    luggage: Yup.string().required("Please Enter Car Luggage"),
    isLuxury: Yup.string().required("Please Select One"),
    description: Yup.string().required("Please Enter the car description"),
    car_features: Yup.array().required("This is required")
  });



  let initial_Values = {
    car_type: getQuery("car_type") ? getQuery("car_type") : "",
    our_fee: getQuery("our_fee")
      ? getQuery("our_fee")
      : "",
    passenger: getQuery("passenger") ? getQuery("passenger") : "",
    luggage: getQuery("luggage") ? getQuery("luggage") : "",
    dispatcher_fee: getQuery("dispatcher_fee") ? getQuery("dispatcher_fee") : "",
    isLuxury: getQuery("isLuxury") ? getQuery("isLuxury") : "",
    description: getQuery("description") ? getQuery("description") : "",
    car_features: car_features ? car_features : [],
    car_pics: [],
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("car_type", values.car_type);
    formData.append("passenger", values.passenger);
    formData.append("our_fee", values.our_fee);
    formData.append("luggage", values.luggage);
    formData.append("dispatcher_fee", values.dispatcher_fee);
    formData.append("isLuxury", values.isLuxury);
    formData.append("description", values.description);
    formData.append("car_features", JSON.stringify(values.car_features))
    for (let i = 0; i < myImages.length; i++) {
      formData.append("car_pics[]", myImages[i], myImages[i].name);
    }
    if (getQuery("update") === "true" && getQuery("id")) {
      formData.append("id", getQuery("id"));
      formData.append("oldImages", JSON.stringify(oldImages));
      let res = await adminService.carUpdate(formData);
      if (res.status) {
        ReactToastSuccess(res.message);
        setLoading(false);
      } else {
        ReactToast("opps something went wrong Please try again");
        setLoading(false);
      }
    } else {
      let res = await adminService.carAdd(formData);
      if (res.status) {
        ReactToastSuccess(res.message);
        setLoading(false);
      } else {
        ReactToast("opps something went wrong Please try again");
        setLoading(false);
      }
    }
  };

  let removeImage = (e) => {
    let v = Object.values(myImages).filter((item) => item !== e);
    setMyImages(v);
  };

  let removeOldImage = (e) => {
    let v = oldImages.filter((item) => item !== e);
    setOldImages(v);
  };

  useEffect(() => {
    if (getQuery("car_pics")) {
      let img = JSON.parse(getQuery("car_pics"));
      setOldImages(img);
    }
  }, []);

  return (
    <>
      <div className="right_cntnt">
        <div className="d-flex sett_row">
          <div className="userSedit right_box">
            <div className="userEditForm">
              <Formik
                innerRef={formikRef}
                initialValues={initial_Values}
                enableReinitialize={true}
                validationSchema={ProfileSchema}
                onSubmit={(values) => {
                  console.log(values.car_features)
                  handleSubmit(values);
                }}
              >
                {({ errors, touched, values }) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-5 form-group with_210 margRt60">
                        <label className="form_lbl paddLft15">Car Type</label>
                        <Field
                          type="text"
                          name="car_type"
                          placeholder="UP TO 9 PAX VAN"
                          className={`form-control ${touched.car_type && errors.car_type
                            ? "is-invalid"
                            : ""
                            }`}
                          autoComplete="off"
                        />
                        {errors.car_type && touched.car_type ? (
                          <div className="d-block invalid-feedback ">
                            {errors.car_type}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-5 form-group with_210 margRt60">
                        <label className="form_lbl paddLft15">
                          Car Price (per/mile)
                        </label>
                        <Field
                          type="number"
                          name="our_fee"
                          placeholder="$ 140"
                          className={`form-control ${touched.our_fee && errors.our_fee
                            ? "is-invalid"
                            : ""
                            }`}
                          autoComplete="off"
                        />
                        {errors.our_fee && touched.our_fee ? (
                          <div className="d-block invalid-feedback ">
                            {errors.our_fee}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-5 form-group with_210 margRt60">
                        <label className="form_lbl">Number of Passengers</label>
                        <Field
                          type="number"
                          name="passenger"
                          placeholder="Enter Number of Passengers"
                          className={`form-control ${touched.passenger && errors.passenger
                            ? "is-invalid"
                            : ""
                            }`}
                          autoComplete="off"
                        />
                        {errors.passenger && touched.passenger ? (
                          <div className="d-block invalid-feedback ">
                            {errors.passenger}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-5 form-group with_210 margRt60">
                        <label className="form_lbl">Luggage Count</label>
                        <Field
                          type="number"
                          name="luggage"
                          placeholder="Enter Number of luggage"
                          className={`form-control ${touched.luggage && errors.luggage
                            ? "is-invalid"
                            : ""
                            }`}
                          autoComplete="off"
                        />
                        {errors.luggage && touched.luggage ? (
                          <div className="d-block invalid-feedback ">
                            {errors.luggage}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-5 form-group with_210 margRt60">
                        <label className="form_lbl">Dispatcher Fee</label>
                        <Field
                          type="number"
                          name="dispatcher_fee"
                          placeholder="Please Enter Dispatcher Fee"
                          className={`form-control ${touched.dispatcher_fee && errors.dispatcher_fee
                            ? "is-invalid"
                            : ""
                            }`}
                          autoComplete="off"
                        />
                        {errors.dispatcher_fee && touched.dispatcher_fee ? (
                          <div className="d-block invalid-feedback ">
                            {errors.dispatcher_fee}
                          </div>
                        ) : null}

                      </div>
                      <div className="col-md-5 form-group with_210 margRt60">
                        <label className="form_lbl">Luxury</label>
                        <Field
                          as="select"
                          name="isLuxury"
                          className={`form-control ${touched.isLuxury && errors.isLuxury
                            ? "is-invalid"
                            : ""
                            }`}
                          autoComplete="off"
                        >
                          <option value="">Select...</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </Field>
                        {errors.isLuxury && touched.isLuxury ? (
                          <div className="d-block invalid-feedback ">
                            {errors.isLuxury}
                          </div>
                        ) : null}

                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-5 form-group with_210 margRt60">
                        <label className="form_lbl">Description</label>
                        <Field
                          as="textarea"
                          name="description"
                          placeholder="Please Enter Car description"
                          id='description'
                          className={`form-control ${touched.description && errors.description
                            ? "is-invalid"
                            : ""
                            }`}
                          autoComplete="off"
                        />
                        {errors.description && touched.description ? (
                          <div className="d-block invalid-feedback ">
                            {errors.description}
                          </div>
                        ) : null}

                      </div>

                      <div className="col-md-5 form-group with_210 margRt60">
                        <label className="form_lbl">Car Features</label>
                        <div id="add-feature">
                          <label className="d-flex gap-2">
                            <Field
                              type="checkbox"
                              name="car_features"
                              value="Airbags"
                              id="feature"
                              className='mt-1'
                            />
                            <label htmlFor="car_features">
                              <h5>Airbags</h5>
                            </label>
                          </label>
                          <label className="d-flex gap-2">
                            <Field
                              type="checkbox"
                              name="car_features"
                              value="Power Steering"
                              id="feature"
                              className='mt-1'
                            />
                            <label htmlFor="car_features">
                              <h5>Power Steering</h5>
                            </label>
                          </label>
                          <label className="d-flex gap-2">
                            <Field
                              type="checkbox"
                              name="car_features"
                              value="Airconditioning in Car"
                              id="feature"
                              className='mt-1'
                            />
                            <label htmlFor="car_features">
                              <h5>Airconditioning in Car</h5>
                            </label>
                          </label>
                          <label className="d-flex gap-2">
                            <Field
                              type="checkbox"
                              name="car_features"
                              value="Radio"
                              id="feature"
                              className='mt-1'
                            />
                            <label htmlFor="car_features">
                              <h5>Radio</h5>
                            </label>
                          </label>

                          <label className="d-flex gap-2">
                            <Field
                              type="checkbox"
                              name="car_features"
                              value="GPS"
                              id="feature"
                              className='mt-1'
                            />
                            <label htmlFor="car_features">
                              <h5>GPS</h5>
                            </label>
                          </label>
                          <label className="d-flex gap-2">
                            <Field
                              type="checkbox"
                              name="car_features"
                              value="Audio input"
                              id="feature"
                              className='mt-1'
                            />
                            <label htmlFor="car_features">
                              <h5>Audio input</h5>
                            </label>
                          </label>
                          <label className="d-flex gap-2">
                            <Field
                              type="checkbox"
                              name="car_features"
                              value="Bluetooth"
                              id="feature"
                              className='mt-1'
                            />
                            <label htmlFor="car_features">
                              <h5>Bluetooth</h5>
                            </label>
                          </label>

                          <label className="d-flex gap-2">
                            <Field
                              type="checkbox"
                              name="car_features"
                              value="Winter tyres"
                              id="feature"
                              className='mt-1'
                            />
                            <label htmlFor="car_features">
                              <h5>Winter tyres</h5>
                            </label>
                          </label>

                          <label className="d-flex gap-2">
                            <Field
                              type="checkbox"
                              name="car_features"
                              value="Parking Sensor"
                              id="feature"
                              className='mt-1'
                            />
                            <label htmlFor="car_features">
                              <h5>Parking Sensor</h5>
                            </label>
                          </label>

                          <label className="d-flex gap-2">
                            <Field
                              type="checkbox"
                              name="car_features"
                              value="Reversing camera"
                              id="feature"
                              className='mt-1'
                            />
                            <label htmlFor="car_features">
                              <h5>Reversing camera</h5>
                            </label>
                          </label>
                        </div>
                        {errors.car_features && touched.car_features ? (
                          <div className="d-block invalid-feedback ">
                            {errors.car_features}
                          </div>
                        ) : null}
                      </div>


                    </div>

                    <div className="row">
                      <div className="col-md-3 form-group with_210 margRt60">
                        <label className="form_lbl paddLft15">
                          Profile photo
                        </label>
                        <div className="d-flex align-items-center">
                          <span className="profile_pic">
                            {/* <img src={`/images/user.png`} alt="img" /> */}
                          </span>
                          <div className="uploadFile">
                            <Field
                              type="file"
                              name="profilepic"
                              className="form-control"
                              multiple
                              onChange={(event) => {
                                setMyImages((prevImages => [...prevImages, ...event.target.files]));
                              }}
                              autoComplete="off"
                            />
                            <label className="white_btn btn">
                              <img src="/images/img_upload.svg" alt="img" />
                              ADD CAR IMAGES
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      {myImages &&
                        Object.values(myImages).map((e, i) => (
                          <div
                            style={{
                              position: "relative",
                              width: "200px",
                              height: "200px",
                              margin: "10px",
                            }}
                          >
                            <img
                              src={`${URL.createObjectURL(e)}`}
                              alt={i}
                              className="mr-3"
                              style={{
                                width: "100%",
                                "margin-right": "10px",
                              }}
                            />
                            <span
                              style={{
                                position: "absolute",
                                background: "black",
                                borderRadius: "40px",
                                top: 0,
                                right: 0,
                                color: "white",
                                zIndex: 99,
                                cursor: "pointer",
                              }}
                            >
                              <MdImageNotSupported
                                size={20}
                                onClick={() => removeImage(e)}
                              />
                            </span>
                          </div>
                        ))}
                      {oldImages &&
                        oldImages.map((e, i) => (
                          <div
                            style={{
                              position: "relative",
                              width: "200px",
                              height: "200px",
                              margin: "10px",
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/Cars/${e}`}
                              alt={i}
                              className="mr-3"
                              style={{
                                width: "100%",
                                "margin-right": "10px",
                              }}
                            />
                            <span
                              style={{
                                position: "absolute",
                                background: "black",
                                borderRadius: "40px",
                                top: 0,
                                right: 0,
                                color: "white",
                                zIndex: 99,
                                cursor: "pointer",
                              }}
                            >
                              <MdImageNotSupported
                                size={20}
                                onClick={() => removeOldImage(e)}
                              />
                            </span>
                          </div>
                        ))}
                    </div>
                    <div className="prifile_btn">
                      <button
                        type="submit"
                        className="btn save_btn"
                        style={{ backgroundColor: "#24356c", color: "white" }}
                      >
                        {loading ? (
                          <BorderSpinner />
                        ) : (
                          <>
                            <img src="/images/save.svg" alt="img" />
                            ADD
                          </>
                        )}
                      </button>
                      <button type="button" className="btn cancel_btn">
                        <NavLink to="/admin-dashboard/cars">
                          <span className="close_icon">×</span> CANCEL
                        </NavLink>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
