import React from "react";
import {FaCheckCircle} from "react-icons/fa"
const About = () => {
  return (
    <>
      <section id="about">
        <div className="container">
          <div className="row align-items-center my-5">
            <div className="col-md-6">
              <img src="/images/paxVan.png" alt="About Us | Book My Car Now" className="w-100" />
            </div>
            <div className="col-md-6">
              <p className="themeRedColor">About Us</p>
              <h2 className="text-uppercase themeBlueColor">
                Our Commitment to your comfort and satisfaction
              </h2>
              <p className="text-muted">
                Our commitment is to your comfort and satisfaction.  <br />
                We provide clean cars and courteous drivers that will get you to your
                destination safe and fast.
              </p>
              <ul className="list-unstyled themeBlueColor">
                <li>
                <FaCheckCircle /> 24/7 Online Booking
                </li>
                <li>
                <FaCheckCircle />  Diverse Vehicle Selection
                </li>
                <li>
                <FaCheckCircle />  Flexible Pick-up and Drop-off
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default About;
