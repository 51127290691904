/** @format */

import React, { useEffect, useState } from "react";
import { HiUsers } from "react-icons/hi";
import { BsFillBagFill } from "react-icons/bs";
import adminService from "../../services/adminService";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { IoMdAdd } from "react-icons/io";
import { NavLink } from "react-router-dom";

export const Cars = () => {
  const [cars, setCars] = useState(null);
  let getCars = async () => {
    let res = await adminService.getCar();
    if (res.status) {
      setCars(res.cars);
    }
  };
  useEffect(() => {
    getCars();
  }, []);
  return (
    <>
      <div className="container-fluid mt-2">
        <div className="row">
          <div className=" my-3 add-car-btn">
            <NavLink to="/admin-dashboard/addCar">
              <button className="btn-sm btn themeBtnWhite bg-white">
                <IoMdAdd /> ADD NEW CAR
              </button>
            </NavLink>
          </div>
          {cars &&
            cars.map((e, i) => (
              <div className="col-md-3 text-center" key={i}>
                <div className="mt-3">
                  <h5>{e.car_type}</h5>
                  <Carousel>
                    {e.car_pics.map((el, i) => (
                      <div style={{ height: "145px" }} key={i}>
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/Cars/${el}`}
                          alt={`Cars-${i}`}
                        />
                      </div>
                    ))}
                  </Carousel>
                  <div className="vehicle-grid-item-specs  align-item-center">
                    <div className="input-group">
                      <span className="input-group-addon">
                        <HiUsers />
                      </span>
                      <span className="input-group-addon">{e.passenger}</span>
                    </div>
                    <div className="input-group">
                      <span className="input-group-addon">
                        <BsFillBagFill />
                      </span>
                      <span className="input-group-addon">{e.luggage}</span>
                    </div>
                  </div>
                  <div className="mt-4 mb-5">
                    <p className="mb-2 price-per-mile">${e.our_fee} <span>/mile</span></p> 
                    <NavLink
                      to={`/admin-dashboard/addCar?update=true&id=${e.id}&car_type=${e.car_type}&car_pics=${JSON.stringify(e.car_pics)}&luggage=${e.luggage}&passenger=${e.passenger}&our_fee=${e.our_fee}&dispatcher_fee=${e.dispatcher_fee}&isLuxury=${e.isLuxury}&description=${e.description}&car_features=${e.car_features}`}>
                      <button className="btn themeBtnWhite bg-white border">
                        Update Details
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
