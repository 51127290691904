/** @format */

import React from "react";
import Main from "../Sections/Main";
import Testimonials from "../Sections/Testimonials";
import About from "../Sections/About";
// import Fleet from "../Sections/Fleet";
import FAQ from "../Sections/FAQ";
import SearchForm from "../Sections/SearchForm";
import { Helmet } from "react-helmet-async";
const Home = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>
          Car Rental in New York & New Jersey | Airport Transportation
        </title>
        <meta
          name="description"
          content="Your go-to for car rentals in New York and New Jersey. From Newark Airport to Manhattan, or NY Penn Station to JFK & more, we've got you covered."
        />
        <link rel="canonical" href={`${process.env.REACT_APP_URL}`} />
        <meta
          name="keywords"
          content="car rental in newyork,car rental in new jersey, car rental new york ny,car rental usa new york,car hire new jersey usa,car hire usa new york"
        ></meta>
      </Helmet>
      <Main />
      <SearchForm />
      <Testimonials />
      <About />
      <FAQ />
    </>
  );
};

export default Home;
