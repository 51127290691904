import React, { useEffect, useState } from "react";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { BasicDropdown } from "../blocks/Modals/BasicDropdown";
import { AiFillCar } from "react-icons/ai";
// import { TbCarOff } from "react-icons/tb";
import { BsFillEyeFill } from "react-icons/bs";
import { RiEditBoxLine } from "react-icons/ri";
import bookingService from "../services/BookingService";
import BookingDialog from "../blocks/Dialogs/BookingDialog";
import { openBookingDialog } from "../pages/Vehicle/VehicleSlice";
// import { ReactToast, ReactToastSuccess } from "../blocks/Modals/ReactToast";
import { FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
export const Dashboard = () => {
  const [rides, setRides] = useState([]);
  const [sortKey, setSortKey] = useState("pickup_date");
  const [sortOrder, setSortOrder] = useState("desc");
  // const { user } = useSelector((state) => state.auth);
  const [bookingDialogData, setBookingDialogData] = useState(null);
  const dispatch = useDispatch();

  let getRides = async () => {
    let res = await bookingService.getRides();
    if (res.status) {
      setRides(res.result);
    }
  };

  useEffect(() => {
    getRides();
  }, []);
  const handleSort = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  const sortedRides = rides.sort((a, b) => {
    if (sortOrder === "asc") {
      return a[sortKey] > b[sortKey] ? 1 : -1;
    } else {
      return a[sortKey] < b[sortKey] ? 1 : -1;
    }
  });
  // let cancelBooking = async (id) => {
  //   let confirm = window.confirm(
  //     "Are you sure, you want to cancel this booking?"
  //   );
  //   if (confirm) {
  //     let res = await bookingService.cancelBooking(id, "cancel");
  //     if (res.status) {
  //       ReactToastSuccess(res.message);
  //     } else {
  //       ReactToast("opps something went wrong");
  //     }
  //   } else {
  //     return;
  //   }
  // };
  return (
    <>
      <>
        <MainHeader />
        <>
          <div className="container mt-5 mb-5">
            <div className="page-heading style3 wrapper border-bottom ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <h1>Reservations</h1>
                </div>
                {/* <div className="col-lg-6 col-md-6 col-sm-6">
                  <p className="breadcrumbs">
                    <span typeof="v:Breadcrumb">
                      <NavLink to="/">Home</NavLink>
                    </span>
                    <span className="delimiter">|</span>
                    <span className="current">Limo Quote</span>
                  </p>
                </div> */}
              </div>
            </div>
          </div>

          <div className="container find-vehicle-container">
            <header id="top" className="ors-menu-top">
              <nav className="navbar">
                <div className="container-fluid" id="accountInformationHeader">
                  <div
                    style={{
                      color: "rgb(15 101 228 / 87%)",
                      cursor: "pointer",
                      fontSize: "15px",
                      marginLeft: "5px",
                    }}
                    className="ml-0"
                  >
                    <RiEditBoxLine color="" size={18} />
                    <NavLink to="/" className="ml-1">
                      New Reservation
                    </NavLink>
                  </div>
                  <div>{/* <BasicDropdown name={user?.user.fname} /> */}</div>
                </div>
              </nav>
            </header>

            <div className="row">
              <div className="col-md-12  p-3">
                <div className="container">
                  <div className="row ride-info">
                    <ul
                      className="ac-nav hidden-xs"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        listStyle: "none",
                      }}
                    >
                      <li className="active">
                        <NavLink to="#" title="Rides">
                          <span className="dashboard-btn active">
                            <AiFillCar margin={0} /> Rides
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className="col-12 mt-3 table-responsive">
                    <table className="table ac-data-table ac-table-collapse ac-table-primary ac-table-responsive">
                      <thead className="active">
                        <tr id="ridesTableHead">
                          <th className="sortable number text-center">
                            {/* <input
                              type="checkbox"
                              name="select-all-rides"
                              // onclick="Ores4Rides.SelectAllRides(this);"
                            /> */}
                            <span title="Sort By Confirmation Number">
                              Conf. #
                            </span>
                          </th>
                          <th
                            className="sortable active descending text-center"
                            onClick={() => handleSort("pickup_date")}
                          >
                            {sortKey === "pickup_date" ? (
                              sortOrder === "desc" ? (
                                <FaSortAlphaUp />
                              ) : (
                                <FaSortAlphaDown />
                              )
                            ) : null}
                            <span title="Sort By Date">Date</span>
                          </th>
                          <th
                            className="sortable active descending text-center"
                            onClick={() => handleSort("pickup_time")}
                          >
                            {sortKey === "pickup_time" ? (
                              sortOrder === "desc" ? (
                                <FaSortAlphaUp />
                              ) : (
                                <FaSortAlphaDown />
                              )
                            ) : null}
                            <span title="Sort By Time">Time</span>
                          </th>
                          <th
                            className="sortable text-center"
                            onClick={() => handleSort("Passenger")}
                          >
                            {sortKey === "Passenger" ? (
                              sortOrder === "desc" ? (
                                <FaSortAlphaUp />
                              ) : (
                                <FaSortAlphaDown />
                              )
                            ) : null}
                            <span title="Sort By Passenger">Passenger</span>
                          </th>
                          <th
                            className="text-center"
                            onClick={() => handleSort("pickup_address")}
                          >
                            {sortKey === "pickup_address" ? (
                              sortOrder === "desc" ? (
                                <FaSortAlphaUp />
                              ) : (
                                <FaSortAlphaDown />
                              )
                            ) : null}
                            <span title="Sort By Pickup Address">
                              Pickup Address
                            </span>
                          </th>
                          <th
                            className="text-center"
                            onClick={() => handleSort("dropoff_address")}
                          >
                            {sortKey === "dropoff_address" ? (
                              sortOrder === "desc" ? (
                                <FaSortAlphaUp />
                              ) : (
                                <FaSortAlphaDown />
                              )
                            ) : null}
                            <span title="Sort By Dropoff Address">
                              Dropoff Address
                            </span>
                          </th>
                          <th
                            className="sortable text-center"
                            onClick={() => handleSort("status")}
                          >
                            {sortKey === "status" ? (
                              sortOrder === "desc" ? (
                                <FaSortAlphaUp />
                              ) : (
                                <FaSortAlphaDown />
                              )
                            ) : null}
                            <span title="Sort By Status">Status</span>
                          </th>
                          <th
                            className="sortable total text-center"
                            onClick={() => handleSort("fare")}
                          >
                            {sortKey === "fare" ? (
                              sortOrder === "desc" ? (
                                <FaSortAlphaUp />
                              ) : (
                                <FaSortAlphaDown />
                              )
                            ) : null}
                            <span title="Sort By Total">Total</span>
                          </th>
                          <th className="actions text-center">
                            <span>Actions</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody id="tableRides">
                        {sortedRides && sortedRides.length > 0 ? (
                          sortedRides.map((el, i) => (
                            <tr
                              id="ridesTableHead"
                              style={{
                                color: el.status === "cancel" ? "red" : null,
                              }}
                              key={i}
                            >
                              <td className="sortable number text-center">
                                <span title="Sort By Confirmation Number">
                                  {i + 1}
                                </span>
                              </td>
                              <td className="sortable active descending text-center">
                                <span title="Sort By Date">
                                  {el.pickup_date}
                                </span>
                              </td>
                              <td className="sortable active descending text-center">
                                <span title="Sort By Time">
                                  {el.pickup_time}
                                </span>
                              </td>
                              <td className="sortable text-center">
                                <span title="Sort By Passenger">
                                  {el.Passenger}
                                </span>
                              </td>
                              <td className="text-start">
                                <span>{el.pickup_address}</span>
                              </td>
                              <td className="text-start">
                                <span>{el.dropoff_address}</span>
                              </td>
                              <td className="sortable text-center">
                                <span style={{ textTransform: "capitalize" }}>
                                  {el.status}
                                </span>
                              </td>
                              <td>
                                <span title="Sort By Total">${el.fare}</span>
                              </td>
                              <td className="actions text-center ">
                                <span>
                                  {/* {el.status  === "cancel"? null:
                                    <button
                                      disabled={
                                        el.status == "Confirm" ||
                                        el.status == "cancel"
                                          ? true
                                          : false
                                      }
                                      style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                        padding: "5px",
                                      }}
                                      onClick={() => cancelBooking(el.id)}
                                    >
                                      <TbCarOff size={22} color={el.status === "cancel" ? "red" : "#002250"} />
                                    </button> 
                                    } */}
                                  <button
                                    style={{
                                      border: "none",
                                      backgroundColor: "transparent",
                                      cursor: "pointer",
                                      padding: "5px",
                                    }}
                                    onClick={() => {
                                      setBookingDialogData(el);
                                      dispatch(openBookingDialog());
                                    }}
                                  >
                                    <BsFillEyeFill
                                      size={22}
                                      color={
                                        el.status === "cancel"
                                          ? "red"
                                          : "#002250"
                                      }
                                    />
                                  </button>
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="9">
                              <div className="content-empty text-center">
                                <span className="text-muted">
                                  No Rides available
                                </span>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="text-center pb-5">
              <NavLink to="/privacy-policy"> Privacy Policy </NavLink>
            </div> */}
          </div>

          {/* <div className="row row-fluid padding-bottom20 mt-2">
            <h2 align="center" style={{ fontWeight: "700" }}>
              Our <img src="/images/five-stars.png" width="200" height="118" />{" "}
              Reviews &amp; Testimonials
            </h2>
          </div> */}
        </>
        <MainFooter />
        {bookingDialogData && <BookingDialog booking={bookingDialogData} />}
      </>
    </>
  );
};
