/** @format */

import axios from "axios";
import { store } from "../App";

 const instance = axios.create({
   baseURL: process.env.REACT_APP_BASE_URL,
 });
 
instance.interceptors.request.use(
  function (config) {
    if (store.getState()?.auth?.user) {
      const token = `Bearer ${store?.getState()?.auth?.user?.token}`;
      config.headers.authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
