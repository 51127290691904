/** @format */

import { NavLink, Outlet, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";

export const Sidebar = (props) => {
  // const { pathname } = useLocation();

  return (
    <>
      <div className={props.sidebarFull ? "side_bar sidebar_full" : "side_bar"}>
        <div className="side_menu text-center color-dark">
          <ul className="list-unstyled">
            <li>
              <NavLink to="/admin-dashboard">
                <span className="menu_cricle">
                  <img
                    className="gray_icon"
                    src="/images/dash.svg"
                    alt="imgDash"
                  />
                </span>
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin-dashboard/bookings"
                title="Properties"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <span className="menu_cricle">
                  <img
                    className="gray_icon"
                    src="/images/pricing.svg"
                    alt="img"
                  />
                </span>
                Bookings
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin-dashboard/users"
                title="Properties"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <span className="menu_cricle">
                  <img
                    className="gray_icon"
                    src="/images/user.svg"
                    alt="img"
                  />
                </span>
                Users
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin-dashboard/cars" title="Map search">
                <span className="menu_cricle">
                  <img className="gray_icon" src="/images/car.svg" alt="img" />
                </span>
                Cars
              </NavLink>
            </li>

            <li>
              <NavLink to="/admin-dashboard/addCar">
                <span className="menu_cricle">
                  <img className="gray_icon" src="/images/info.svg" alt="img" />
                </span>
                Car Add
              </NavLink>
            </li>

            {/* {admin?.theadmin.role === "admin" && (
              <li>
                <NavLink
                  to="/admin-dashboard/subadmin"
                  title="Campaign"
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }>
                  <span className="menu_cricle">
                    <img className="gray_icon" src={adminpng} alt="img" />
                  </span>
                  Sub Admin
                </NavLink>
              </li>
            )} */}
          </ul>
          <ul className="list-unstyled expander_icon d-none d-lg-block">
            <li>
              <span>
                <img
                  className="gray_blue peauto"
                  src="/images/Expander.svg"
                  alt="img"
                  onClick={props.showFullsidebar}
                />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <section
        className={
          props.sidebarFull
            ? "main_sec dash_board mainpadd230"
            : "main_sec dash_board"
        }
      >
        <Outlet />
      </section>
    </>
  );
};
