/** @format */

import React from "react";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";
import { Helmet } from "react-helmet-async";
const RefundPolicy = () => {
  return (
    <>
    <Helmet prioritizeSeoTags>
        <title>Refund Policy | Car Rental in Ny & NJ | Airport Transportation</title>
        <meta
          name="description"
          content=""
        />
        <link rel="canonical" href={`${process.env.REACT_APP_URL}refund-policy`} />
        <meta
          name="keywords"
          content="car rental in newyork,car rental in new jersey, car rental new york ny,car rental usa new york,car hire new jersey usa,car hire usa new york"
        ></meta>
      </Helmet>
      <MainHeader />
      <section id="refundPolicyPage">
        <div className="container">
          <div className="row  my-4">
            <h1 className="text-center">Refund Policy</h1>
          </div>
          <div className="refundPolicyDiv mb-5  mx-1">
            <ul className="h5">
              <li className="my-2">
                <b>24-Hour Return: </b>We have a <i>24-hour return policy</i>,
                which means you have 24-hours before your scheduled trip to
                request a refund.
              </li>
              <li className="my-2">
                <b>How To Refund: </b>To start a refund,you can cancel your
                booking or you can contact us at <a href="mailto:support@bookmycarnow.com">support@bookmycarnow.com</a>.
              </li>
              <li className="my-2">
                <b>Always Contact: </b>You can always contact us for any refund
                questions at <a href="mailto:support@bookmycarnow.com">support@bookmycarnow.com</a>.
              </li>
            </ul>
            <h3 className="mt-4">Refunds:</h3>
            <p>
              We will notify you to let you know if the refund was approved or
              not. If approved, you'll be automatically refunded on your
              original payment method <b>within 10 business days</b>. Please remember
              it can take some time for your bank or credit card company to
              process and post the refund too.
            </p>
            <p>
              If more than <b>15 business days</b> have passed since we've approved
              your return, please contact us at <a href="mailto:support@bookmycarnow.com">support@bookmycarnow.com</a>.
            </p>
          </div>
        </div>
      </section>
      <MainFooter />
    </>
  );
};
export default RefundPolicy;
