import React, { useEffect } from "react";
import { FaUser, FaTachometerAlt, FaStar } from 'react-icons/fa';
import ContentLoader from 'react-content-loader'
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addVehicleCurruntSelected } from "../pages/Vehicle/VehicleSlice";

const VehiclesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.formData);
  const { cars } = useSelector((state) => state.formData);
  useEffect(() => {
    dispatch(addVehicleCurruntSelected(null));
  });

  let Book = (e) => {
    dispatch(addVehicleCurruntSelected(e));
    navigate("/book-car-detail");
  };

  return (
    <>
      {!loading && cars.length > 0 ? (
        <section id="vehicle">
          <div className="container">
            <ul className="list-group list-group-flush my-5">
              {cars && cars.map((e, i) => (
                <li className="list-group-item" key={i}>
                  <div className="row p-3 border-0 align-items-center justify-content-center text-center justify-content-md-start text-md-start">
                    <div className="col-md-6">
                      <Carousel className="w-auto">
                        {e.car_pics.map((el, i) => (
                          <Carousel.Item key={i}>
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}/Cars/${el}`}
                              alt={`${e.car_type}-${i}`}
                            />
                          </Carousel.Item>
                        ))}
                      </Carousel>

                    </div>
                    <div className="col-md-6">
                      <h5 className="text-uppercase themeBlueColor">{e.car_type}</h5>
                      <small className="rating text-muted ">
                        <FaStar size={15} className="text-warning" />
                        <FaStar size={15} className="text-warning" />
                        <FaStar size={15} className="text-warning" />
                        <FaStar size={15} className="text-warning" />
                        <FaStar size={15} className="text-warning" /> 5.0
                        (1.5k)
                      </small>
                      <div className="featureIcon d-flex align-items-center justify-content-center justify-content-md-start text-md-start">


                        <i className="px-3 py-2 m-2 h3 rounded-3">
                          <FaUser size={25} />
                        </i>
                        <i className="px-3 py-2 m-2 h3 rounded-3">
                          <FaTachometerAlt size={25} />
                        </i>
                      </div>
                      <div className="d-flex align-items-center text-center justify-content-center justify-content-md-start text-md-start">
                        <small className="px-2 mx-1"> {Number(e.passenger) + 1} Seat</small>
                        <small className="px-4 mx-3">AC</small>
                      </div>
                      <p>Price: ${Number(e?.fare).toFixed(2)}</p>
                      <button
                        className="btn border px-3 mt-3 themeBtnRed"
                        onClick={() => Book(e)}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
      ) : (
        <section id="dummy-list">
          <div className="container">
            <ul className="list-group list-group-flush my-5">
              {[1, 2].map((i) => (
                <li className="list-group-item" key={i}>
                  <div className="row p-3 border-0 align-items-center justify-content-center text-center">
                    <div className="col-md-6">
                      <ContentLoader
                        viewBox="0 0 400 200"
                        width="100%"
                        height="100%"
                        title="Search for results..."
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="70%"
                          height="70%"
                        />
                      </ContentLoader>
                    </div>
                    <div className="col-md-6">
                      <ContentLoader
                        viewBox="0 0 400 200"
                        width="100%"
                        height="100%"
                        title="Search For results..."
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="50%"
                          height="10%"
                        />
                        <rect
                          x="0"
                          y="15%"
                          rx="5"
                          ry="5"
                          width="30%"
                          height="4%"
                        />
                        <rect
                          x="2%"
                          y="22%"
                          rx="2"
                          ry="2"
                          width="20px"
                          height="20px"
                        />
                        <rect
                          x="9%"
                          y="22%"
                          rx="2"
                          ry="2"
                          width="20px"
                          height="20px"
                        />
                        <rect
                          x="16%"
                          y="22%"
                          rx="2"
                          ry="2"
                          width="20px"
                          height="20px"
                        />
                        <rect
                          x="23%"
                          y="22%"
                          rx="2"
                          ry="2"
                          width="20px"
                          height="20px"
                        />
                        <rect
                          x="0"
                          y="40%"
                          rx="5"
                          ry="5"
                          width="15%"
                          height="10%"
                        />
                      </ContentLoader>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
      )}
    </>
  );
};

export default VehiclesList;
