import React from 'react'
import { MainHeader } from '../components/MainHeader'
import MainFooter from '../components/MainFooter'
import { Link, useLocation } from 'react-router-dom'

const MailConfirm = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const email = query.get('email');
    return (
        <>
            
            <MainHeader />

            <div className="container find-vehicle-container mt-5 mb-5">
                <div className="row p-5">
                    <div className="col-2">
                    </div>
                    <div className="col-8">
                        <h2 className="text-center">
                            Check your email
                        </h2>
                        <p className="text-center">Check your <b>{email}</b> inbox for reset your password.</p>
                    </div>
                    <div className="text-center">
                        <Link to='/login' className='link-underline-primary'>Go back to Login Page</Link>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
            </div>
            <MainFooter />

        </>
    )
}

export default MailConfirm