/** @format */

import { GetApi, PostApi, UpdateApi } from "./index";

class GlobalService {
  contactUs(data) {
    return PostApi(`/query/contactUs`, data);
  }

  async getPlaces(value) {
    //getting places from open street api
    const params = {
      q: value,
      format: "json",
      addressdetails: 1,
      countrycodes: "us",
      //Must Be ISO countrycodes: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
    };
    const queryString = new URLSearchParams(params).toString();
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const response = await fetch(
      `${process.env.REACT_APP_OPENSTREET_API}?${queryString}`,
      requestOptions
    );

    const data = await response.json();
    return data.map((item, index) => ({
      value: item.display_name,
      label: item.display_name,
      lat: item.lat,
      lon: item.lon,
      key: index,
    }));
  }
}

const globalService = new GlobalService();

export default globalService;
