/** @format */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { closeBookingDialog } from "../../pages/Vehicle/VehicleSlice";

export default function BookingDialog(props) {
  const { bookingDialog } = useSelector((state) => state.vehicle);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeBookingDialog());
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={bookingDialog}
        onClose={handleClose}
      >
        <DialogTitle>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="selectedBG py-2">Booking Details</h3>
              </div>
              <div className="col-sm-6 text-start mt-sm-4">
                <p>Booking Id: {props.booking.id}</p>
              </div>
              <div className="col-sm-6 text-sm-end mt-sm-4">
                <p>
                  Created: {new Date(props.booking.createdAt).toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="container-fluid">
            <div className="row mt-sm-2">
              <div className="col-md-6 text-start">
                <h3>Pickup Address</h3>
                <p>{props.booking.pickup_address}, </p>
                <p>{props.booking.pickup_date}, </p>
                <p>{props.booking.pickup_time}</p>
              </div>
              <div className="col-md-6 text-md-end">
                <h3>Drop Off Address</h3>
                <p>{props.booking.dropoff_address} </p>
                <h3>Payment Id</h3>
                <p>{props.booking.uniqueReference} </p>
              </div>
              {props.booking.Passengers &&
                props?.booking.Passengers.map((e, i) => (
                  <div className="col-md-6 border" key={i}>
                    <div className="row">
                      <div className="col-sm-6 text-start dialogBG">
                        <h5>Name:</h5>
                      </div>
                      <div className="col-sm-6 text-sm-end dialogBG">
                        <h5>
                          {e.fname} {e.lname}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 text-start">
                        <h5>Phone:</h5>
                      </div>
                      <div className="col-sm-6 text-sm-end">
                        <h5>{e.phone}</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 text-start dialogBG">
                        <h5>Mail:</h5>
                      </div>
                      <div className="col-sm-6 text-sm-end dialogBG">
                        <h5>{e.email}</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 text-start">
                        <h5>Luxury Service Fee:</h5>
                      </div>
                      <div className="col-sm-6 text-sm-end">
                        <h5>{e.luxuryService ? "Yes" : "No"}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="col-md-6 border">
                <div className="row">
                  <div className="col-sm-6 text-start dialogBG">
                    <h5>Status:</h5>
                  </div>
                  <div className="col-sm-6 text-sm-end dialogBG">
                    <h5 style={{ textTransform: "capitalize" }}>
                      {props.booking.status}{" "}
                    </h5>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-6 text-start">
                    <h5>Service Type:</h5>
                  </div>
                  <div className="col-sm-6 text-sm-end">
                    <h5>{props.booking.ServiceTypeId} </h5>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-6 text-start dialogBG">
                    <h5>Passenger:</h5>
                  </div>
                  <div className="col-sm-6 text-sm-end dialogBG">
                    <h5>{props.booking.Passenger} </h5>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-6 text-start">
                    <h5>Total Paid:</h5>
                  </div>
                  <div className="col-sm-6 text-sm-end">
                    <h5>{props.booking.fare} </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
