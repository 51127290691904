/** @format */

import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

const MainFooter = () => {
  let [ishomepage, setIsHomepage] = useState(false);
  let location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setIsHomepage(true);
    }
  }, [location.pathname]);
  return (
    <>
      <footer className="footerBG">
        <div className="container">
          <div className="row py-5 mx-2">
            <div className="col-md-3 col-12">
              <div className="row align-items-sm-center">
                <div className="col-12">
                  <NavLink className="navbar-brand text-md-start text-center" to="/">
                    <img
                      src="/images/book-my-car-logo-v1.png"
                      alt="Book My Car Now Logo"
                      className="w-75"
                    />
                  </NavLink>
                  <p>
                    Our commitment is to your comfort and satisfaction. We
                    provide clean cars and courteous drivers that will get you
                    to your destination safe and fast.
                  </p>
                </div>
                <div className="col-12 social_icons d-flex align-items-center justify-content-center">
                  <a
                    href="/"
                    className="text-decoration-none text-white d-flex align-items-center p-2 m-1 border rounded-circle"
                  >
                    <FaFacebook />
                  </a>
                  {/* <a
                    href="/"
                    className="text-decoration-none text-white d-flex align-items-center p-2 m-1 border rounded-circle"
                  >
                    <FaInstagram />
                  </a> */}
                  <a
                    href="/"
                    className="text-decoration-none text-white d-flex align-items-center p-2 m-1 border rounded-circle"
                  >
                    <FaTwitter />
                  </a>
                  {/* <a
                    href="/"
                    className="text-decoration-none text-white d-flex align-items-center p-2 m-1  border rounded-circle"
                  >
                    <FaLinkedin />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6 mt-4 mt-mb-0">
              <p className="text-uppercase">Quick Links</p>
              <ul className="list-unstyled">
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    Home
                  </NavLink>
                </li>
                <li className="py-1">
                  <a
                    href={
                      ishomepage
                        ? "#service"
                        : "https://www.bookmycarnow.com/#service"
                    }
                    className="text-decoration-none text-white"
                  >
                    Service
                  </a>
                </li>
                <li className="py-1">
                  <a
                    href={
                      ishomepage
                        ? "#about"
                        : "https://www.bookmycarnow.com/#about"
                    }
                    className="text-decoration-none text-white"
                  >
                    About Us
                  </a>
                </li>
                <li className="py-1">
                  <a
                    href={
                      ishomepage ? "#faq" : "https://www.bookmycarnow.com/#faq"
                    }
                    className="text-decoration-none text-white"
                  >
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 col-6 mt-4 mt-mb-0">
              <p className="text-uppercase">Top Searched City</p>
              <ul className="list-unstyled">
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    <FaChevronRight size={15} />
                    New York
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    <FaChevronRight size={15} />
                    Atlanta
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    <FaChevronRight size={15} />
                    Denver
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    <FaChevronRight size={15} />
                    Queens
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    <FaChevronRight size={15} />
                    Bronex
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-md-2 col-6 mt-4 mt-mb-0">
              <p className="text-uppercase">Legel</p>
              <ul className="list-unstyled">
                <li className="py-1">
                  <NavLink
                    to="/privacy-policy"
                    className="text-decoration-none text-white"
                  >
                    Privacy Policy
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink
                    to="/refund-policy"
                    className="text-decoration-none text-white"
                  >
                    Refund Policy
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink
                    to="/contact-us"
                    className="text-decoration-none text-white"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li className="py-1">
                  <a href="#about" className="text-decoration-none text-white">
                    About Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-6 mt-4 mt-mb-0">
              <p className="text-uppercase">Contact</p>
              <ul className="list-unstyled">
                <li className="py-1">
                  <a
                    href="https://maps.app.goo.gl/BwDhgHtxfJ5Yo39L8"
                    className="text-decoration-none text-white"
                    target="_blank"
                  >
                    259-10 hillside ave, Glen Oaks NY 11004
                  </a>
                </li>
                <li className="py-1">
                  <a
                    href="mailto:support@bookmycarnow.com"
                    className="text-decoration-none text-white"
                  >
                    support@bookmycarnow.com
                  </a>
                </li>
                <li className="py-1">
                  <a
                    href="tel:1234567890"
                    className="text-decoration-none text-white"
                  >
                    +1 (516) 361-7300
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="text-center">
          <div className="tagsDiv mx-2">
            <span className="tags">Tags: </span>
            <small className="px-2 border-end">
              <NavLink className="text-white" to="#">
                Compact Sedan
              </NavLink>
            </small>
            <small className="px-2 border-end">
              <NavLink className="text-white" to="#">
                Compact SUV
              </NavLink>
            </small>
            <small className="px-2 border-end">
              <NavLink className="text-white" to="#">
                Full size SUV/Minivan
              </NavLink>
            </small>
            <small className="px-2 border-end">
              <NavLink className="text-white" to="#">
                EV/Hybrid
              </NavLink>
            </small>
            <small className="px-2 border-end">
              <NavLink className="text-white" to="#">
                Luxury Sedan
              </NavLink>
            </small>
            <small className="px-2 border-end">
              <NavLink className="text-white" to="#">
                Luxury SUV
              </NavLink>
            </small>
          </div>
        </div>
        <hr />
        <div className="copyright text-center py-3 mx-2">
          Copyright By{" "}
          <NavLink className="text-white" to="/">
            BookMyCarNow.Com
          </NavLink>
          . All Right Reserved
        </div>
      </footer>
    </>
  );
};

export default MainFooter;
