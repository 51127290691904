import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const fetchOptions = createAsyncThunk(
  "form/fetchOptions",
  async (value) => {
    const params = {
      q: value,
      format: "json",
      addressdetails: 1,
      countrycodes:"us" 
    //Must Be ISO countrycodes: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
    };
    const queryString = new URLSearchParams(params).toString();
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const response = await fetch(
      `${process.env.REACT_APP_OPENSTREET_API}?${queryString}`,
      requestOptions
    );
    const data = await response.json();
    return data.map((item, index) => ({
      value: item.display_name,
      label: item.display_name,
      lat: item.lat,
      lon: item.lon,
      key: index,
    }));
  }
);

const initialState = {
  pickUpAddress:"",
  dropOffAddress:"",
  pickUpDate: "",
  pickUpTime: "",
  options: [],
  pickUpOption:[],
  dropOffOption:[],
  extraData:[],
  cars:[],
  selected:"",
  loading:false,
  total:""
};

const formSlice = createSlice({
  name: "formData",
  initialState: initialState,
  reducers: {
    setPickUpAddress(state, action) {
      state.pickUpAddress = action.payload;
    },
    setDropOffAddress(state, action) {
      state.dropOffAddress = action.payload;
    },
    setPickUpDate(state, action) {
      state.pickUpDate = action.payload;
    },
    setPickUpTime(state, action) {
      state.pickUpTime = action.payload;
    },
    setPickUpOption(state, action) {
      state.pickUpOption = action.payload
    },
    setDropOffOption(state, action) {
      state.dropOffOption = action.payload
    },
    setExtraData(state, action) {
      state.extraData = action.payload
    },
    setSelected(state, action) {
      state.selected = action.payload
    },
    setCars(state, action) {
      state.cars = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setTotal(state, action) {
      state.total = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOptions.fulfilled, (state, action) => {
        state.options = action.payload;
      })
  },
});

export const { setPickUpAddress, setDropOffAddress, setPickUpDate, setPickUpTime, setPickUpOption, setDropOffOption, setExtraData, setCars, setSelected, setLoading,setTotal } = formSlice.actions;

export default formSlice;
