/** @format */

import React, { useEffect, useRef, useState } from "react";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactToast } from "../blocks/Modals/ReactToast";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { clearError, requestToLogin } from "./authSlice";
import { useDispatch, useSelector } from "react-redux";
import { BorderSpinner } from "../blocks/Loaders/BorderSpinner";
import { Helmet } from "react-helmet-async";
import authservice from "../services/authService";
import ReCAPTCHA from "react-google-recaptcha";

export const Login = (props) => {
  const { user, error, status } = useSelector((state) => state.auth);
  const [recaptchaToken, setReCaptchaToken] = useState();
  const formikRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required("Enter Your Email"),
    password: Yup.string().required("Enter The Password"),
  });

  const values = {
    email: "",
    password: "",
  };
  const captchaChange = (data) => {
    setReCaptchaToken(data)
  }

  const handleSubmit = async (values) => {
    const res = await authservice.reCaptcha({
      token: recaptchaToken,
    })

    if (res.success === false) {
      ReactToast("invalid Captcha")
      return false;
    }

    dispatch(requestToLogin(values));
  };

  useEffect(() => {
    if (error) {
      ReactToast(error);
      dispatch(clearError());
    }
  }, [error]);

  useEffect(() => {
    if (user === null) {
      if (props.fake) {
        return;
      } else {
        navigate("/login");
      }
    } else {
      user?.user?.role === "user"
      ? navigate("/dashboard")
      : navigate("/login");
    }
  }, [user]);
  return (
    <>
    <Helmet prioritizeSeoTags>
        <title>Login Now! | Car Rental in Ny & NJ | Airport Transportation</title>
        <meta
          name="description"
          content="Your go-to for car rentals in NY & NJ. From Newark Airport to Manhattan, or NY Penn Station to JFK & more, we've got you covered.Login Now!"
        />
        <link rel="canonical" href={`${process.env.REACT_APP_URL}login`} />
        <meta
          name="keywords"
          content="car rental in newyork,car rental in new jersey, car rental new york ny,car rental usa new york,car hire new jersey usa,car hire usa new york"
        ></meta>
      </Helmet>
      {props.fake ? <></> :<MainHeader />}
        <div className="container find-vehicle-container m-70">
          <div className="row">
            <div className="col-md-6 p-5">
              <div className="container">
                <div className="row ride-info">
                  <span className="panel-title ml-5">
                    <span>Registered User</span>
                  </span>
                </div>
              </div>
              <div className="ors-col-max-width mt-4">
                <Formik
                  innerRef={formikRef}
                  enableReinitialize={true}
                  initialValues={values}
                  validationSchema={LoginSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {({ errors, touched }) => (
                    <Form className="login_form position-relative">
                      <div className="form-group form-group-lg">
                        <label htmlFor="username-login" className="label-lg">
                          Email Address / Username
                        </label>
                        <Field
                          type="text"
                          id="username-login"
                          name="email"
                          tabIndex="4"
                          autoFocus=""
                          autoComplete="email"
                          className={`The-form-select  ${
                            touched.email && errors.email
                              ? "is-invalid form-control"
                              : ""
                          }`}
                      />
                      {errors.email && touched.email ? (
                        <div className="d-block invalid-feedback ">
                          {errors.email}
                        </div>
                      ) : null}
                      <div
                        className="text-danger"
                        style={{ display: "none" }}
                        id="username-login-required-message"
                      >
                        Username is required
                      </div>
                    </div>
                    <div className="form-group form-group-lg mt-4">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label htmlFor="password-login" className="label-lg">
                          Password
                        </label>
                        <div className="pull-right">
                          <NavLink
                            to="/forgotpassword"
                            id="forgotPassLink"
                            className="text-danger"
                          >
                            Forgot password?
                          </NavLink>
                        </div>
                      </div>
                      <Field
                        type="password"
                        id="password-login"
                        name="password"
                        tabIndex="5"
                        autoComplete="current-password"
                        className={`The-form-select  ${touched.password && errors.password
                          ? "is-invalid form-control"
                          : ""
                          }`}
                      />
                      {errors.password && touched.password ? (
                        <div className="d-block invalid-feedback ">
                          {errors.password}
                        </div>
                      ) : null}
                      <div className='formGroup m-2'>
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={captchaChange}
                          className="g-recaptcha"
                        />
                      </div>
                      <div className="form-group mt-4">
                        <button type="submit" className="themeBtnRed btn w-100">
                          {status === "loading" ? <BorderSpinner /> : "Log In"}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="text-center mt-3">
              Don't have an account?{" "}
              <NavLink to="/register" className="text-danger">Register Now</NavLink>
            </div>
          </div>
          <div className="col-md-6 p-5 mb-4">
            <div className="container">
              <div className="row ride-info">
                <span className="panel-title ml-5">
                  <span>Why should I log in?</span>
                </span>
              </div>
            </div>
            <div className="ors-col-max-width mt-5">
              <ul className="ors-list-default">
                <li>Book rides even faster using stored account details.</li>
                <li>Modify trip details.</li>
                <li>Access invoices and payment receipts.</li>
                <li>Reporting tools.</li>
              </ul>
            </div>
            {/* <div className="text-center mt-4">
                <button className="mt-5 p-2" onClick={()=>CheckOutGuest()} >Checkout as Guest</button>
              </div> */}
          </div>
        </div>
      </div>
      {props.fake ? <></> : <MainFooter />}
    </>
  );
};
