/** @format */

import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import Flatrate from "./Flatrate";
import Airport from "./Airport";
import { useSelector, useDispatch } from "react-redux";
import { setSelected } from "./formSlice";
const SearchForm = () => {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.formData.selected);
  return (
    <>
      <section className="form_section">
        <div className="container form_container position-relative pt-5">
          <div className="row justify-content-center text-center form_selecter">
            <NavLink
              className={classNames(
                "col-5 col-lg-2 col-md-3 text-decoration-none rounded-start p-3",
                { selectedBG: selected !== "airport" }
              )}
              to={void 0}
              rel-ele="flatrate"
              onClick={() => dispatch(setSelected("flatrate"))}
            >
              Flat Rate
            </NavLink>
            <NavLink
              className={classNames(
                "col-5 col-lg-2 col-md-3 text-decoration-none rounded-end p-3",
                { selectedBG: selected === "airport" }
              )}
              to={void 0}
              rel-ele="airport"
              onClick={() => dispatch(setSelected("airport"))}
            >
              Airport Pickup
            </NavLink>
          </div>
          {selected === "airport" ? <Airport /> : <Flatrate />}
        </div>
      </section>
    </>
  );
};

export default SearchForm;
