import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { MainHeader } from '../components/MainHeader'
import MainFooter from '../components/MainFooter'
import { useNavigate } from 'react-router-dom'
import { ReactToast } from '../blocks/Modals/ReactToast'
import authservice from '../services/authService'
import ReCAPTCHA from 'react-google-recaptcha'
import { BorderSpinner } from '../blocks/Loaders/BorderSpinner'

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [recaptchaToken, setReCaptchaToken] = useState();
    const [data, setData] = useState({
        email: ''
    });

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    const captchaChange = (data) => {
        setReCaptchaToken(data)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)
        const responce = await authservice.reCaptcha({
            token: recaptchaToken,
        })

        if (responce.success === false) {
            ReactToast("invalid Captcha")
            setLoading(false)
            return false;
        }

        const value = {
            email: data.email
        };

        const res = await authservice.forgotPassword(value)

        if (res === false) {
            ReactToast("User Not Found")
            setLoading(false)
        } else {
            navigate(`/mailconfirm?email=${data.email}`);
        }

    }


    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Reset Password </title>
                <meta
                    name="description"
                    content="For rental cars in New York and New Jersey, this is the place to go. We provide coverage from JFK to Manhattan, from Newark Airport to Penn Station, and more."
                />
                <meta
                    name="keywords"
                    content="car rental in newyork,car rental in new jersey, car rental new york ny,car rental usa new york,car hire new jersey usa,car hire usa new york"
                ></meta>
            </Helmet>

            <MainHeader />

            <div className="container find-vehicle-container m-70">
                <div className="row">
                    <div className="col-md-6 p-5">
                        <div className="container">
                            <div className="row ride-info">
                                <span className="panel-title ml-5">
                                    <span>Reset Password</span>
                                </span>
                            </div>
                        </div>
                        <div className="ors-col-max-width mt-4">
                            <form onSubmit={handleSubmit} className='login_form position-relative'>
                                <div className="pb-4">
                                    <div className="form-group form-group-lg">
                                        <label htmlFor="fname" className="label-lg">
                                            Enter Your Email
                                        </label>
                                    </div>
                                    <input type="email"
                                        name='email'
                                        value={data.email}
                                        className="w-100 p-2 border border-gray"
                                        placeholder='Email'
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className='formGroup mt-n5'>
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        onChange={captchaChange}
                                        className="g-recaptcha"
                                    />
                                </div>

                                <div className="form-group mt-2">
                                    <button type="submit" className="themeBtnRed btn w-100">
                                        {loading ? <BorderSpinner /> : "Reset Password"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <MainFooter />
        </>
    )
}

export default ForgotPassword