import { Field, Formik, Form } from "formik";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { IoMdLock } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Login } from "../Auth/Login";
import { setPassenger } from "../pages/Vehicle/VehicleSlice";
import { Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import bookingService from "../services/BookingService";
import { setTotal } from "./formSlice";
import Payroc from "./Payroc";
const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tollFee, setTollFee] = useState("0");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [luxuryService, setLuxuryService] = useState(true);
  const { curruntVehicle, searchVehicle, passengersData } = useSelector(
    (state) => state.vehicle
  );
  const { pickUpOption, dropOffOption, total } = useSelector(
    (state) => state.formData
  );
  const { user } = useSelector((state) => state.auth);
  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);
  const formikRef = useRef();
  useEffect(() => {
    if (!curruntVehicle) {
      navigate("/");
    }
  }, [curruntVehicle, navigate]);

  const PassengerSchema = Yup.object().shape({
    Passenger: Yup.array()
      .min(1, "Need at least a Passenger")
      .of(
        Yup.object().shape({
          fname: Yup.string().required("First Name is required"),
          lname: Yup.string().required("Last Name is required"),
          phone: Yup.string().required("Phone Name is required"),
          email: Yup.string()
            .email("Invalid email")
            .required("Email is required"),
        })
      ),
  });

  const values = {
    fname: user ? user?.user?.fname : "",
    lname: user ? user?.user?.lname : "",
    phone: user ? user?.user?.phone : "",
    email: user ? user?.user?.email : "",
    luxuryService: luxuryService,
  };

  const getTollsPrice = async (p, d) => {
    const e = {
      pickuplat: p?.lat,
      pickuplng: p?.lon,
      dropofflat: d?.lat,
      dropofflng: d?.lon,
    };
    let res = await bookingService.getTollsPrice(e);
    if (res.status && res.price) {
      setTollFee(res.price);
    } else {
      console.log("Error in getTollsPrice");
    }
  };

  useEffect(() => {
    getTollsPrice(pickUpOption, dropOffOption);
  }, [user, pickUpOption, dropOffOption])



  useEffect(() => {
    if (curruntVehicle) {
      const fare = Number(curruntVehicle.fare);
      const tollPrice = Math.round(fare + tollFee);
      const isLuxury = Math.round(fare + (fare * .18) + 10);
      const t = Math.round(curruntVehicle.isLuxury && luxuryService ? isLuxury : tollPrice);
      dispatch(setTotal(t));
    }
  }, [tollFee, luxuryService, curruntVehicle, dispatch]);

  const bookingDetail = {
    fare: curruntVehicle?.fare,
    toll: tollFee,
    totalPrice: total,
    pickUpAddress: searchVehicle?.pickUpAddress,
    dropOffAddress: searchVehicle?.dropOffAddress,
    passengersData: passengersData,
    isLuxury: curruntVehicle?.isLuxury
  }

  const handleSubmit = async (values) => {
    localStorage.setItem("bookingDetail", JSON.stringify(bookingDetail))
    dispatch(setPassenger([values]));
    showModal();
  };

  return (
    <>
      {!user || (user.user.role !== "user" && curruntVehicle) ? (
        <Login fake={true} />
      ) : (
        curruntVehicle && (

          // <section className="lightGreyBg1">
          //   <div className="container py-5">
          //     <Formik
          //       innerRef={formikRef}
          //       enableReinitialize={true}
          //       initialValues={values}
          //       validationSchema={PassengerSchema}
          //       onSubmit={(values) => {
          //         handleSubmit(values);
          //       }}
          //     >
          //       {({ errors, touched, values }) => (
          //         <Form>
          //           <div className="row justify-content-between">
          //             <div className="col-md-6 border">
          //               <div className="row detail-info">
          //                 <span className="panel-title ml-2">
          //                   <span className="panelHeadTitle">
          //                     Passenger Information
          //                   </span>
          //                 </span>
          //               </div>
          //               <div className="ors-col-max-width">
          //                 <div className="form-group">
          //                   <div className="row">
          //                     <div className="col-xs-6 col-md-6">
          //                       <label htmlFor="fname">Fist Name</label>
          //                       <div className="input-group" id="fnameHolder">
          //                         <Field
          //                           autoComplete="off"
          //                           id="fname"
          //                           placeholder="First Name"
          //                           name="fname"
          //                           type="text"
          //                           className="The-form-select"
          //                           disabled={user ? true : false}
          //                         />
          //                         {errors?.Passenger?.fname &&
          //                           touched?.Passenger?.fname && (
          //                             <div className="d-block invalid-feedback">
          //                               {errors.Passenger.fname}
          //                             </div>
          //                           )}
          //                       </div>
          //                     </div>
          //                     <div className="col-xs-6 col-md-6">
          //                       <label htmlFor="lname">Last Name</label>
          //                       <div className="input-group" id="lnameHolder">
          //                         <Field
          //                           autoComplete="off"
          //                           data-val="true"
          //                           placeholder="Last Name"
          //                           id="lname"
          //                           name="lname"
          //                           type="text"
          //                           className="The-form-select"
          //                           disabled={user ? true : false}
          //                         />
          //                         {errors?.Passenger?.lname &&
          //                           touched?.Passenger?.lname && (
          //                             <div className="d-block invalid-feedback">
          //                               {errors.Passenger.lname}
          //                             </div>
          //                           )}
          //                       </div>
          //                     </div>
          //                     <div className="col-xs-12 mt-4 col-md-12">
          //                       <label htmlFor="phone">Passenger Phone</label>
          //                       <div className="input-group" id="phoneHolder">
          //                         <Field
          //                           autoComplete="off"
          //                           data-val="true"
          //                           id="phone"
          //                           placeholder="(555) 555-5555"
          //                           name="phone"
          //                           type="text"
          //                           className="The-form-select"
          //                           disabled={user ? true : false}
          //                         />
          //                         {errors?.Passenger?.phone &&
          //                           touched?.Passenger?.phone && (
          //                             <div className="d-block invalid-feedback">
          //                               {errors.Passenger.phone}
          //                             </div>
          //                           )}
          //                       </div>
          //                     </div>
          //                     <div className="col-xs-12 mt-4 col-md-12">
          //                       <label htmlFor="email">Email Address</label>
          //                       <div className="input-group" id="emailHolder">
          //                         <Field
          //                           autoComplete="off"
          //                           data-val="true"
          //                           id="email"
          //                           name="email"
          //                           type="text"
          //                           className="The-form-select"
          //                           disabled={user ? true : false}
          //                         />
          //                         {errors?.Passenger?.email &&
          //                           touched?.Passenger?.email && (
          //                             <div className="d-block invalid-feedback">
          //                               {errors.Passenger.email}
          //                             </div>
          //                           )}
          //                       </div>
          //                     </div>
          //                     {curruntVehicle.isLuxury && (
          //                       <div className="col-xs-12 mt-4 col-md-12">
          //                         <Field
          //                           name="luxuryService"
          //                           type="checkbox"
          //                           id="luxuryService"
          //                           className="me-2"
          //                           checked={luxuryService}
          //                           onChange={(e) =>
          //                             setLuxuryService(e.target.checked)
          //                           }
          //                           style={{
          //                             width: "18px",
          //                             height: "18px",
          //                           }}
          //                         />
          //                         <label htmlFor="luxuryService">
          //                           <h4>18% Luxury Service Fee</h4>
          //                         </label>
          //                         <p className="float-end">Optional</p>
          //                       </div>
          //                     )}
          //                   </div>
          //                   <ul
          //                     id="stopsContainer"
          //                     className="ui-sortable"
          //                   ></ul>
          //                 </div>
          //               </div>
          //             </div>
          //             <div className="col-md-5">
          //               <div className="row detail-info">
          //                 <span className="panel-title ml-2">
          //                   <span className="panelHeadTitle">Pricing</span>
          //                 </span>
          //               </div>
          //               <div className="row few-detail-info mt-3">
          //                 <div className="d-flex justify-content-between">
          //                   <div>
          //                     <h5>Pickup address</h5>
          //                     <p>{searchVehicle.pickUpAddress}</p>
          //                   </div>
          //                   <div>
          //                     <h5>Dropoff address</h5>
          //                     <p>{searchVehicle.dropOffAddress}</p>
          //                   </div>
          //                 </div>
          //                 <Divider />
          //                 <span
          //                   className="panel-title ml-2"
          //                   style={{
          //                     display: "flex",
          //                     justifyContent: "space-between",
          //                   }}
          //                 >
          //                   <span
          //                     style={{
          //                       color: "#4a4a4a",
          //                       fontSize: "14px",
          //                       fontWeight: "500",
          //                     }}
          //                   >
          //                     Fare
          //                   </span>
          //                   <span>${Math.round(curruntVehicle.fare)}</span>
          //                 </span>
          //                 <span
          //                   className="panel-title ml-2"
          //                   style={{
          //                     display: "flex",
          //                     justifyContent: "space-between",
          //                   }}
          //                 >
          //                   <span
          //                     style={{
          //                       color: "#4a4a4a",
          //                       fontSize: "14px",
          //                       fontWeight: "500",
          //                     }}
          //                   >
          //                     Toll Fee
          //                   </span>
          //                   <span>${tollFee}</span>
          //                 </span>
          //                 {curruntVehicle.isLuxury && luxuryService && (
          //                   <span
          //                     className="panel-title ml-2"
          //                     style={{
          //                       display: "flex",
          //                       justifyContent: "space-between",
          //                     }}
          //                   >
          //                     <span
          //                       style={{
          //                         color: "#4a4a4a",
          //                         fontSize: "14px",
          //                         fontWeight: "500",
          //                       }}
          //                     >
          //                       Luxury Service Fee
          //                     </span>
          //                     <span>
          //                       {curruntVehicle.isLuxury && luxuryService
          //                         ? `$${Math.round(curruntVehicle.fare * 0.18)}`
          //                         : "$0.00"}
          //                     </span>
          //                   </span>
          //                 )}
          
          //                 <span
          //                   className="panel-title ml-2"
          //                   style={{
          //                     display: "flex",
          //                     justifyContent: "space-between",
          //                   }}
          //                 >
          //                   <span
          //                     style={{
          //                       color: "#4a4a4a",
          //                       fontSize: "14px",
          //                       fontWeight: "500",
          //                     }}
          //                   >
          //                     Tax
          //                   </span>
          //                   <span>$0.00</span>
          //                 </span>
          //               </div>
          //               <div className="row total-fare-info">
          //                 <span
          //                   className="panel-title ml-2"
          //                   style={{
          //                     display: "flex",
          //                     justifyContent: "space-between",
          //                   }}
          //                 >
          //                   <span
          //                     style={{
          //                       color: "#4a4a4a",
          //                       fontSize: "14px",
          //                       marginTop: "12px",
          //                       fontWeight: "500",
          //                     }}
          //                   >
          //                     Total
          //                   </span>
          //                   <span
          //                     style={{
          //                       color: "#1952c5",
          //                       fontSize: "30px",
          //                       fontWeight: "600",
          //                     }}
          //                   >
          //                     $ {total}
          //                   </span>
          //                 </span>
          //               </div>
          //               <div className="row">
          //                 <button type="submit" className="paynow-btn mt-3">
          //                   Pay now
          //                 </button>
          //               </div>
          //             </div>
          //           </div>
          //         </Form>
          //       )}
          //     </Formik>

          //     <Modal
          //       open={isModalOpen}
          //       onCancel={handleCancel}
          //       footer={false}
          //       width={500}
          //     >
          //       <>
          //         <div className="row detail-info mt-4 p-4">
          //           <span className="panel-title ml-2">
          //             <span
          //               style={{
          //                 color: "#4a4a4a",
          //                 fontSize: "14px",
          //                 fontWeight: "700",
          //               }}
          //             >
          //               Payment Information
          //             </span>
          //           </span>
          //         </div>
          //         <p className="mt-2">
          //           <IoMdLock /> All transactions are safe and secure.
          //           <span style={{ color: "#1952c5", cursor: "pointer" }}>
          //             Click here
          //           </span>
          //           for details.
          //         </p>

          //         <div className="row detail-info mt-3">
          //           <div className="ors-custom-select">
          //             {/* <StripeContainer amount={total} /> */}
          //             <Payroc amount={total} />
          //           </div>
          //           <span
          //             className="field-validation-valid"
          //             data-valmsg-htmlfor="ServiceTypeId"
          //             data-valmsg-replace="true"
          //           ></span>
          //         </div>
          //         <div>
          //           <span></span>
          //         </div>
          //       </>
          //     </Modal>
          //   </div>
          // </section>


          <div className="checkout-conatiner">
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={values}
              validationSchema={PassengerSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              <Form>
                <div className="main-container d-flex">
                  <div className="booking-detail">
                    <div className="booking-heading">
                      <h3 className="fw-bold mb-2">Booking Information</h3>
                    </div>

                    <div className="row justify-content-between">
                      <div className="col-md-6 w-100 p-2 border rounded">
                        <div className="row">
                          <span className="panel-title ml-2">
                            <span className="panelHeadTitle">
                              Passenger Information
                            </span>
                          </span>
                        </div>

                        <div id="form-fname-lname" className="form-group d-flex gap-3">
                          <div className="row w-100">
                            <label htmlFor="fname" className="fw-bold">Fist Name :</label>
                            <div className="input-group" id="fnameHolder">
                              <Field
                                autoComplete="off"
                                id="fname"
                                placeholder="First Name"
                                name="fname"
                                type="text"
                                className="The-form-select rounded mt-1"
                                disabled={user ? true : false}
                              />
                            </div>
                          </div>
                          <div className="row w-100">
                            <label htmlFor="lname" className="fw-bold">Last Name :</label>
                            <div className="input-group" id="fnameHolder">
                              <Field
                                autoComplete="off"
                                id="lname"
                                placeholder="Last Name"
                                name="lname"
                                type="text"
                                className="The-form-select rounded mt-1"
                                disabled={user ? true : false}
                              />
                            </div>
                          </div>
                        </div>

                        <div id="form-email-pho" className="form-group d-flex gap-3 mt-2">
                          <div id="row" className="row w-100">
                            <label htmlFor="email" className="fw-bold">E-mail :</label>
                            <div className="input-group" id="fnameHolder">
                              <Field
                                autoComplete="off"
                                id="email"
                                placeholder="Email"
                                name="email"
                                type="text"
                                className="The-form-select rounded mt-1"
                                disabled={user ? true : false}
                              />
                            </div>
                          </div>
                          <div className="row w-100">
                            <label htmlFor="pho" className="fw-bold">Phone Number :</label>
                            <div className="input-group" id="fnameHolder">
                              <Field
                                autoComplete="off"
                                id="phone"
                                placeholder="Phone Number"
                                name="phone"
                                type="text"
                                className="The-form-select rounded mt-1"
                                disabled={user ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 w-100 p-2 border time-check">
                        <div className="">
                          <div className="row">
                            <span className="panel-title ml-2">
                              <span className="panelHeadTitle fs-5">
                                PickUp Date / Time
                              </span>
                            </span>
                          </div>
                          <div className="date-time d-flex gap-5">
                            <p>{searchVehicle.pickUpDate}</p>
                            <p>{searchVehicle.pickUpTime}</p>
                          </div>
                        </div>

                        {curruntVehicle.isLuxury && (
                          <div className="">
                            <div className="row">
                              <span className="panel-title ml-2">
                                <span className="panelHeadTitle fs-5">
                                  Luxury Service
                                </span>
                              </span>
                            </div>
                            <div className="">
                              <div className="col-xs-12 col-md-12">
                                <Field
                                  name="luxuryService"
                                  type="checkbox"
                                  id="luxuryService"
                                  className="me-2"
                                  checked={luxuryService}
                                  onChange={(e) =>
                                    setLuxuryService(e.target.checked)
                                  }
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                                <label htmlFor="luxuryService">
                                  <h4>18% Luxury Service Fee</h4>
                                </label>
                              </div>
                            </div>
                          </div>
                        )}

                      </div>

                      <div className="col-md-6 w-100 p-2 border">
                        <div className="row">
                          <span className="panel-title ml-2">
                            <span className="panelHeadTitle">
                              Address Information
                            </span>
                          </span>
                        </div>
                        <div className="pickUp">
                          <label className="fw-bold">PickUp Address :</label>
                          <p>{searchVehicle.pickUpAddress}</p>
                        </div>
                        <div className="pickUp">
                          <label className="fw-bold">DropOff Address :</label>
                          <p>{searchVehicle.dropOffAddress}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="checkout-payment border rounded">
                    <div className="booking-heading">
                      <h4 className="fw-bold mb-2">Payment Information</h4>
                    </div>
                    <table class="table border">
                      <tbody>
                        <tr>
                          <th>Fare</th>
                          <td className='text-end'>$ {Math.round(curruntVehicle.fare)}</td>
                        </tr>
                        {curruntVehicle.isLuxury && luxuryService && (
                          <tr>
                            <th>Luxury Service</th>
                            <td className='text-end'>$ {Math.round(curruntVehicle.fare * 0.18)}</td>
                          </tr>
                        )}
                        <tr>
                          <th>Toll Fee</th>
                          <td className='text-end'>$ {tollFee}</td>
                        </tr>
                        <tr>
                          <th>Total Amount</th>
                          <td className='text-end'>$ {total}</td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="payment-method">
                      <div className="heading">
                        <h6 className="fw-bold mb-2">Payment Mathod</h6>
                      </div>
                      <div className="card-img d-flex gap-3 w-100">
                        <img class src="/images/visaCard.png" alt="" />
                        <img class src="/images/masterCard.png" alt="" />
                        <img class src="/images/americanexpress.png" alt="" />
                        <img class src="/images/discovercard.png" alt="" />
                      </div>
                    </div>
                    <div className="pay-btn mt-5 fs-6">
                      <input type="checkbox" required className="input-ckeck" /> You are agree to our &nbsp;
                      <Link to='/privacy-policy'> Privacy Policy.</Link>
                      <button id='booking-btn'
                        className='text-light btn btn-danger w-100 mt-3'
                      >
                        Pay Now
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>

        )
      )}
      <Modal
        style={{
          'marginTop': '-50px'
        }}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        width={500}
      >
        <div className="payment-box row p-2 rounded border">
          <h5 className="fw-bold mt-1">
            Enter Card Information
          </h5>
        </div>
        <p className="mt-2">
          <IoMdLock /> All transactions are safe and secure.&nbsp;
          <a href="/">
            Click here
          </a>&nbsp;
          for details.
        </p>

        <div className="payment-box row rounded border">
          <Payroc amount={total} />
          <span
            className="field-validation-valid"
            data-valmsg-htmlfor="ServiceTypeId"
            data-valmsg-replace="true"
          ></span>
        </div>
      </Modal>
    </>
  );
};

export default Checkout;
