/** @format */

import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSelected } from "../Sections/formSlice";
import { BasicDropdown } from "../blocks/Modals/BasicDropdown";
import { FaPhoneAlt } from "react-icons/fa";
import ScrollToTop from "./ScrollToTop";
export const MainHeader = () => {
  const [collapse, setCollapse] = useState(false);
  const dispatch = useDispatch();
  let [ishomepage, setIsHomepage] = useState(false);
  let location = useLocation();
  const { user } = useSelector((state) => state.auth);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const [anchorEl2, setAnchorEl2] = React.useState(null);
  // const open2 = Boolean(anchorEl2);
  // const handleClick2 = (event) => {
  //   setAnchorEl2(event.currentTarget);
  // };
  // const handleClose2 = () => {
  //   setAnchorEl2(null);
  // };

  useEffect(() => {
    if (location.pathname === "/") {
      setIsHomepage(true);
      dispatch(setSelected("flatrate"));
    }
  }, [location.pathname]);
  return (
    <>
      <ScrollToTop />
      <header className="h_Shadow">
        <div className="container">
          <nav className="navbar navbar-expand-lg  navbar-light">
            <div className="col-lg-3 col-md-6 col-9 d-flex">
              <NavLink className="navbar-brand" to="/">
                <img
                  src="/images/book-my-car-logo-v1.png"
                  alt="Book My Car Now Logo"
                  className="w-75"
                />
              </NavLink>
              <button
                className={`navbar-toggler ${
                  collapse ? "collapsed" : ""
                } border-0 shadow-none position-absolute m-3 end-0`}
                type="button"
                data-bs-toggle="collapse"
                // data-bs-target="#homeNavbar"
                aria-controls="homeNavbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setCollapse(!collapse)}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div
              className={`collapse navbar-collapse col-md-9 ${
                collapse ? "show" : ""
              }`}
              id="homeNavbar"
            >
              <ul className="Menu navbar-nav me-auto">
                <li className="nav-item active mx-3">
                  <NavLink className="nav-a text-muted" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item mx-3">
                  <a className="nav-a text-muted" href={ishomepage ? "#service" : "https://www.bookmycarnow.com/#service"}>
                    Service
                  </a>
                </li>
                <li className="nav-item mx-3">
                  <a className="nav-a text-muted" href={ishomepage ? "#about" : "https://www.bookmycarnow.com/#about"}>
                    About
                  </a>
                </li>
                <li className="nav-item mx-3">
                  <NavLink className="nav-a text-muted" to="/contact-us">
                    Contact Us
                  </NavLink>
                </li>
                <li className="nav-item mx-3">
                  <a className="nav-a text-muted" href={ishomepage ? "#faq" : "https://www.bookmycarnow.com/#faq"}>
                    FAQ
                  </a>
                </li>
              </ul>
              <ul className="Menu navbar-nav align-items-lg-center">
                <li className="nav-item my-3">
                  <a
                    className="themeBlueColor text-decoration-none mx-4"
                    href="tel:1234567890"
                  >
                    <FaPhoneAlt className="m-1" /> +1 (516) 361-7300
                  </a>
                </li>
                <li className="nav-item mx-3">
                  {!user ? (
                    <NavLink
                      className="btn themeBtnRed px-4 border-0"
                      to="/login"
                    >
                      Log in
                    </NavLink>
                  ) : (
                    <BasicDropdown name={user?.user.fname} />
                  )}
                </li>
              </ul>
            </div>
            {/* <div className="col-md-4 text-end">
              <a
                className="themeBlueColor text-decoration-none mx-4"
                href="tel:1234567890"
              >
                <i className="bi bi-telephone"></i> +1 (516)361-7300
              </a>
              <a className="btn themeBtnRed px-4 border-0" href="/login">
                Log in
              </a>
            </div> */}
          </nav>
        </div>
      </header>
    </>
  );
};
