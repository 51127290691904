import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import paymentService from "../services/paymentService";
import { useSelector } from "react-redux";
import bookingService from "../services/BookingService";
import { ReactToast, } from "../blocks/Modals/ReactToast";
import { useNavigate } from "react-router-dom";
import { BorderSpinner } from "../blocks/Loaders/BorderSpinner";

const Payroc = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { curruntVehicle, searchVehicle, passengersData, user } = useSelector(
    (state) => ({
      ...state.vehicle,
      user: state.auth.user.user,
    })
  );
  const initialValues = {
    amount: props.amount,
    cardholderName: "",
    card: "",
    expiry: "",
    cvv: "",
    pin: "",
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const createBooking = async (data) => {
    let res = await bookingService.createBook(data);
    if (res.status && res.status === true) {
      navigate(`/createBook?status=true&&bookingId=${res.bookingId}`);
    } else {
      navigate("/createBook?status=false");
    }
  };

  const onFinish = async (values) => {
    setLoading(true)
    let res = await paymentService.createPayment(values);
    if (res.status && res.status === true) {
      if (
        res.transactionResult.status &&
        res.transactionResult.status === "COMPLETE"
      ) {
        const data = {
          CarId: curruntVehicle?.id,
          uid: user?._id,
          ServiceTypeId: searchVehicle?.ServiceTypeId,
          fare: Math.round(props.amount).toString(),
          pickup_address: searchVehicle?.pickUpAddress,
          pickup_date: searchVehicle?.pickUpDate,
          pickup_time: searchVehicle?.pickUpTime,
          pickup_lat: searchVehicle?.pickUpLat,
          pickup_lng: searchVehicle?.pickUpLon,
          dropoff_address: searchVehicle?.dropOffAddress,
          dropoff_lat: searchVehicle?.dropOffLat,
          dropoff_lng: searchVehicle?.dropOffLon,
          Passenger: searchVehicle?.no_of_passenger,
          Luggage: searchVehicle?.luggage_count,
          flight_number: searchVehicle?.flight_number,
          Passengers: JSON.stringify(passengersData),
          transaction_status: res.transactionResult.status,
          uniqueReference: res.uniqueReference,
        };
        createBooking(data);
        setLoading(false)
      } else {
        setLoading(false)
        ReactToast(`${res.transactionResult.status}: Opps somethig went wrong`);
      }
    } else {
      setLoading(false)
      ReactToast("Opps somethig went wrong");
    }
  };

  return (
    <>
      {/* <div className="container m-2">
        <Form
          layout="vertical"
          name="flatrate"
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          id="Payroc"
        >
          <div className="row pr-3">
            <Form.Item name="amount" className="d-none">
              <Input type="hidden" disabled />
            </Form.Item>
            <div className="col-12">
              <div className="d-flex flex-column">
                <Form.Item
                  name="cardholderName"
                  className="w-100"
                  data-val="true"
                  label="Card Holder Name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Card Holder Name",
                    },
                  ]}
                >
                  <Input placeholder="Card Holder Name" />
                </Form.Item>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex flex-column">
                <Form.Item
                  name="card"
                  className="w-100"
                  data-val="true"
                  label="Card Number"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Card Number",
                    },
                  ]}
                >
                  <Input type="number" placeholder="1234 5678 435678" />
                </Form.Item>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex flex-column">
                <Form.Item
                  name="expiry"
                  className="w-100"
                  data-val="true"
                  label="Expiry"
                  rules={[
                    {
                      required: true,
                      message: "Enter Your Card Expiry Year",
                      min: 4,
                      max: 4,
                      maxLength: 4,
                    },
                  ]}
                >
                  <Input type="number" placeholder="YYYY" maxLength={4} />
                </Form.Item>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex flex-column">
                <Form.Item
                  name="cvv"
                  className="w-100"
                  data-val="true"
                  label="CVV/CVC"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your CVV/CVC",
                      min: 3,
                    },
                  ]}
                >
                  <Input type="password" placeholder="***" maxLength={3} />
                </Form.Item>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex flex-column">
                <Form.Item
                  name="pin"
                  className="w-100"
                  data-val="true"
                  label="Pincode"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your Pincode",
                    },
                  ]}
                >
                  <Input placeholder="Pincode" />
                </Form.Item>
              </div>
            </div>
            <div className="col-12 mt-3">
              <Form.Item className="w-100">
                <Button
                  htmlType="submit"
                  className="btn btn-success w-100 text-white"
                >
                  Pay now
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div> */}


      <div id="payroc-container" className="payroc-container mt-2">
        <div className="container m-2">
          <Form
            layout="vertical"
            name="flatrate"
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            id="Payroc"
          >
            <div className="row pr-3">
              <Form.Item name="amount" className="d-none">
                <Input type="hidden" disabled />
              </Form.Item>
              <div className="col-12">
                <div id="card-name" className="d-flex flex-column">
                  <Form.Item
                    name="cardholderName"
                    className="w-100"
                    data-val="true"
                    label="Card Holder Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Card Holder Name",
                      },
                    ]}
                  >
                    <Input placeholder="Card Holder Name" />
                  </Form.Item>
                </div>
              </div>
              <div className="col-12">
                <div id="card-name" className="d-flex flex-column">
                  <Form.Item
                    name="card"
                    className="w-100"
                    data-val="true"
                    label="Card Number"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Card Number",
                      },
                    ]}
                  >
                    <Input placeholder="Card Holder Name" />
                  </Form.Item>
                </div>
              </div>

              <div className="col-6">
                <div id="card-expiry" className="d-flex flex-column">
                  <Form.Item
                    name="expiry"
                    className="w-100"
                    data-val="true"
                    label="Expiry"
                    rules={[
                      {
                        required: true,
                        message: "Enter Your Card Expiry Year",
                        min: 4,
                        max: 4,
                        maxLength: 4,
                      },
                    ]}
                  >
                    <Input type="number" placeholder="YYYY" maxLength={4} />
                  </Form.Item>
                </div>
              </div>
              <div className="col-6">
                <div id="card-cvv" className="d-flex flex-column">
                  <Form.Item
                    name="cvv"
                    className="w-100"
                    data-val="true"
                    label="CVV/CVC"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your CVV/CVC",
                        min: 3,
                      },
                    ]}
                  >
                    <Input type="password" placeholder="***" maxLength={3} />
                  </Form.Item>
                </div>
              </div>
              <div className="col-12">
                <div id="card-name" className="d-flex flex-column">
                  <Form.Item
                    name="pin"
                    className="w-100"
                    data-val="true"
                    label="Pincode"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Pincode",
                      },
                    ]}
                  >
                    <Input placeholder="Pincode" />
                  </Form.Item>
                </div>
              </div>
              <div id="btn-sub" className="col-12 mt-3">
                <Form.Item className="w-100">
                  <Button
                    htmlType="submit"
                    className=""
                  >
                    {loading === true ? <BorderSpinner /> : "Pay now"}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Payroc;
