import React from 'react'

export const TextArea = ({ field, form, id, ...props }) => {
  const { name, value } = field;
  return (
    <textarea
      type="text"
      id={id}
      {...field}
      {...props}
      name={name}
      value={value}
      className="form-control mt-1"
      rows="3"
      cols="10"></textarea>
  );
};