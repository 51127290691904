/** @format */

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FullLoader } from "../../blocks/Loaders/FullLoader";
import { ReactToast, ReactToastSuccess } from "../../blocks/Modals/ReactToast";
import {
  removeSearch,
  removeSearchVehicle,
  removeSelect,
  removeVehicleCurruntSelected,
} from "./VehicleSlice";
import { setTotal } from "../../Sections/formSlice";
import { useSearchParams } from 'react-router-dom';

const CreateBooking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  useEffect(() => {
    if (status === "true") {
      dispatch(removeVehicleCurruntSelected());
      dispatch(removeSearchVehicle());
      dispatch(removeSearch());
      dispatch(removeSelect());
      dispatch(setTotal("0"));
      ReactToastSuccess("Booking Created Successfully");
      navigate("/thankyou");
    } else {
      ReactToast("Opps somethig went wrong");
    }
  }, []);

  return (
    <>
      <FullLoader />
    </>
  );
};

export default CreateBooking;
