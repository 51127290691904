import React, { useState } from 'react';
import { Carousel, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import LoginPopUp from './LoginPopUp';
import { useSelector } from 'react-redux';

const BookingProcessDialog = () => {
    const { user } = useSelector((state) => state.auth)

    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const showLogin = () => setIsLoginOpen(true);
    const CancelLogin = () => setIsLoginOpen(false);
    let carouselRef = null;

    const [currentIndex, setCurrentIndex] = useState(0);

    const next = () => {
        carouselRef.next();
    };

    const prev = () => {
        carouselRef.prev();
    };

    const navigate = useNavigate();

    const data = [
        {
            img: '/images/slide-map.png',
            text: "Step 1 of 4",
            detail: `Enter PickUp and DropOff Details, along with Date Time`,

        },
        {
            img: 'https://app.bookmycarnow.com/api/Cars/16983260087233.png',
            text: "Step 2 of 4",
            detail: 'Choose your favorite Car',
            pera: "You can Choose your favorite car for a ride and embark on a journey tailored to your style and comfort."
        },
        {
            img: '/images/safe-slide.png',
            text: "Step 3 of 4",
            detail: 'Pay Safe & Risk Free',
            pera: "Your transactions are safeguarded with the latest encryption technology, ensuring your financial information remains protected."
        },
        {
            img: '/images/owner-avatar.png',
            text: "Step 4 of 4",
            detail: 'Booking Confrimation',
            pera: 'If there are any problem in booking in car, we have a dedicated support team who are here to assist you.',
            key: 4,
        },
    ]


    function afterChangeCol(index) {
        setCurrentIndex(index)
    }

    const handleClick = () => {
        if (user === null) {
            showLogin();
        } else {
            navigate('/book-now')
        }
    }

    return (
        <>
            <div className='dialog-container'>
                <Carousel ref={(c) => (carouselRef = c)} dotPosition="top" afterChange={afterChangeCol}>
                    {data.map((item, i) => (
                        <div className='main-slider'>
                            <div className="img-box">
                                <img src={item.img}
                                    alt=""
                                    id={i === 1 ? 'slide-img' : ''}
                                    className={i === 1 ? "h-100 w-75" : "mt-3"}
                                />
                            </div>
                            <div className="pera">
                                <h5>{item.text}</h5>
                                <p className='head'>{item.detail}</p>
                                <p className='dt'>{item.pera}</p>
                            </div>
                        </div>
                    ))}
                </Carousel>
                <div className='btn-box'>
                    <button onClick={prev} className='prev'>
                        Previous
                    </button>
                    {currentIndex === 3 ?
                        <button className='bookbtn'
                            onClick={handleClick}
                        >
                            Proceed to Booking
                        </button> :
                        <button onClick={next} className='next'>Next</button>}
                </div>
            </div >
            <Modal
                open={isLoginOpen}
                onCancel={CancelLogin}
                footer={false}
                width={600}
            >
                <LoginPopUp />
            </Modal>
        </>
    );

};

export default BookingProcessDialog;
