/** @format */

import React from "react";
import MainFooter from "../../components/MainFooter";
import { MainHeader } from "../../components/MainHeader";
import Home from "../../pages/Home";

export const MainLayout = () => {
  return (
    <>
      <MainHeader />
      <Home />
      <MainFooter />
    </>
  );
};
