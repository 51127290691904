/** @format */

import Dropdown from "react-bootstrap/Dropdown";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { LogoutTheUser } from "../../Auth/authSlice";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export const BasicDropdown = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, error, status } = useSelector((state) => state.auth);
  const logout = () => {
    dispatch(LogoutTheUser());
    navigate(user && user?.user?.role === "admin" ? "/admin-login" : "/login");
  };
  const dashboard = () => {
    navigate(
      user && user?.user?.role === "admin" ? "/admin-dashboard" : "/dashboard"
    );
  };
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="trasparent"
        id="dropdown-basic"
        className="themeBlueColor"
      >
        <FaUser size={20} />
        <span
          style={{
            color: "#172254 ",
            cursor: "pointer",
            fontSize: "1rem",
            marginLeft: "5px",
          }}
        >
          {props.name}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="themeBlueColor">
        <Dropdown.Item>Welcome, {user?.user.fname}</Dropdown.Item>
        <Dropdown.Item onClick={dashboard}>Dashboard</Dropdown.Item>
        <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
