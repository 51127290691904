import React from 'react'

export const BorderSpinner = () => {
  return (
    <>
      <div className="spinner-border" role="status">
      </div>
    </>
  );
}
