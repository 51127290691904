import React from "react";
import { MainHeader } from "../components/MainHeader";
import SearchForm from "../Sections/SearchForm";
import VehiclesList from "../Sections/VehiclesList";
import MainFooter from "../components/MainFooter";

const Results = () => {
  return (
    <>
      <MainHeader />
      <SearchForm />
      <VehiclesList />
      <MainFooter />
    </>
  );
};

export default Results;
