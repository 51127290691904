import React, { useEffect, useState } from 'react'
import adminService from '../../services/adminService'
import Pagination from "@mui/material/Pagination";
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';

const Users = () => {
  const [userData, setUserData] = useState([]);
  const [pageApi, setPageApi] = useState(1);
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState('desc')
  const [filterStr, setFilterStr] = useState();
  const handleSort = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc")
    } else {
      setSortKey(key)
      setSortOrder("asc")
    }
  }

  const handleFilter = () => {
    const filterQry = `${query}${filterStr ? `&filterStr=${filterStr}` : ""}`
    getUsers(filterQry)
  }

  const getUsers = async (query) => {
    const res = await adminService.getUsers(query);
    if (res.status === true) {
      setUserData(res)
    }
  }

  const query = `page=${pageApi}&sortKey=${sortKey}&sortOrder=${sortOrder}`

  useEffect(() => {
    getUsers(query);
  }, [query])
  return (
    <>
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-12">
            <div className="row filterDiv mt-3">
              <div className="col-9 d-flex justify-content-center">
                <input
                  type="text"
                  className="mx-3 w-75 text-center py-1"
                  placeholder="Filter With Name, Email Or Phone Number"
                  onChange={(e) => {
                    setFilterStr(e.target.value);
                  }}
                />
              </div>
              <div className="col-3">
                <button
                  type="submit"
                  className="mx-3 text-center w-75 py-1 rounded"
                  onClick={() => handleFilter()}
                  style={{
                    color: "white",
                    backgroundColor: "#24356C",
                  }}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 mt-3 table-responsive">
            <table className="table ac-data-table ac-table-collapse ac-table-primary ac-table-responsive">
              <thead className="active">
                <tr id="usersTableHead">
                  <th className="sortable number text-center">
                    <span>Conf. #</span>
                  </th>
                  <th
                    className="sortable number text-center"
                    onClick={() => handleSort('fname')}
                  >
                    {sortKey === "fname" ? (
                      sortOrder === "desc" ? (
                        <FaSortAlphaUp />
                      ) : (
                        <FaSortAlphaDown />
                      )
                    ) : (
                      ""
                    )}
                    <span>Name</span>
                  </th>
                  <th className="sortable number text-center">
                    <span>Email</span>
                  </th>
                  <th className="sortable number text-center">
                    <span>Phone</span>
                  </th>
                  <th className="sortable number text-center">
                    <span>Email Verified</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {userData ? (
                  userData.users && userData.users.map((item, i) => (
                    <tr>
                      <td className="sortable number text-center">
                        <span>{i + 1}</span>
                      </td>
                      <td className="sortable number text-center">
                        {item.fname}
                      </td>
                      <td className="sortable number text-center">
                        {item.email}
                      </td>
                      <td className="sortable number text-center">
                        {item.phone}
                      </td>
                      <td className="sortable number text-center">
                        {item.isVarify === true ? "Yes" : "No"}
                      </td>
                    </tr>
                  ))) : (
                  <tr>
                    <td colSpan="5">
                      <div className="content-empty text-center">
                        <span className="text-muted">No User available</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-2 mb-4">
        <Pagination
          count={userData.pages}
          onChange={(e, value) => setPageApi(value)}
          color="primary"
        />
      </div>
    </>
  )
}

export default Users

