/** @format */

import React, { useRef, useState } from "react";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextArea } from "../blocks/Forms/TextArea";
import globalService from "../services/globalService";
import { ReactToast, ReactToastSuccess } from "../blocks/Modals/ReactToast";
import { Helmet } from "react-helmet-async";
import ReCAPTCHA from "react-google-recaptcha";
import authservice from "../services/authService";
export const ContactUs = () => {
  const formikRef = useRef();
  const [recaptchaToken, setReCaptchaToken] = useState();

  const LoginSchema = Yup.object().shape({
    fullname: Yup.string().required("Enter The Fullname"),
    email: Yup.string().email().required("Enter Your Email"),
    phone: Yup.string().required("Enter The phone"),
  });

  const values = {
    fullname: "",
    email: "",
    phone: "",
    comment: ""
  };

  const captchaChange = (data) => {
    setReCaptchaToken(data)
  }

  const handleSubmit = async (values) => {
    const responce = await authservice.reCaptcha({
      token: recaptchaToken,
    })

    if (responce.success === false) {
      ReactToast("invalid Captcha")
      return false;
    }

    let res = await globalService.contactUs(values);
    if (res.status) {
      ReactToastSuccess(res.message)
    } else {
      ReactToast("opps something went wrong")
    }
   
  };
   
  return (
    <>
    <Helmet prioritizeSeoTags>
        <title>
          Contact Us | Car Rental in NY & NJ | Airport Transportation
        </title>
        <meta
          name="description"
          content="Contact us Now! For car rentals in New York and New Jersey. From Newark Airport to Manhattan, or NY Penn Station to JFK & more, we've got you covered."
        />
        <link rel="canonical" href={`${process.env.REACT_APP_URL}contact-us`} />
        <meta
          name="keywords"
          content="car rental in newyork,car rental in new jersey, car rental new york ny,car rental usa new york,car hire new jersey usa,car hire usa new york"
        ></meta>
      </Helmet>
      <MainHeader />
      <>
        <div className="container my-4">
          <div className="row">
            <div className="col-md-6 p-5">
              <h3>Contact Us</h3>
              <div className="container"></div>
              <div className="ors-col-max-width mt-4">
                <Formik
                  innerRef={formikRef}
                  enableReinitialize={true}
                  initialValues={values}
                  validationSchema={LoginSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}>
                  {({ errors, touched }) => (
                    <Form className="login_form position-relative">
                      <div className="form-group form-group-lg">
                        <label for="username-login" className="label-lg">
                          Fullname <font color="#FF0000">*</font>
                        </label>
                        <Field
                          type="text"
                          id="username-login"
                          name="fullname"
                          tabIndex="4"
                          autoFocus=""
                          className={`form-control mt-1 ${
                            touched.fullname && errors.fullname
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                      <div className="form-group form-group-lg">
                        <label for="username-login" className="label-lg">
                          Email <font color="#FF0000">*</font>
                        </label>
                        <Field
                          type="text"
                          id="username-login"
                          name="email"
                          tabIndex="4"
                          autoFocus=""
                          autoComplete="email"
                          className={`form-control mt-1 ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                      <div className="form-group form-group-lg">
                        <label for="username-login" className="label-lg">
                          Phone <font color="#FF0000">*</font>
                        </label>
                        <Field
                          type="text"
                          id="username-login"
                          name="phone"
                          tabIndex="4"
                          autoFocus=""
                          autoComplete="email"
                          className={`form-control mt-1 ${
                            touched.phone && errors.phone ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                      <div className="form-group form-group-lg">
                        <label for="username-login" className="label-lg">
                          Comments
                        </label>
                        <Field
                          component={TextArea}
                          name="comment"
                          id="username-login"
                        />
                      </div>

                      <div className='formGroup m-2'>
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={captchaChange}
                          className="g-recaptcha"
                        />
                      </div>

                      <div className="form-group mt-4 text-center">
                        <button type="submit" className="w-100 btn themeBtnRed ">
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 p-5 col-sm-6 mt-1">
              <h2
                className="wow animated fadeIn animated"
                data-animation="fadeIn"
                style={{ visibility: "visible", animationName: "fadeIn" }}>
                Contact Info
              </h2>
              <div className="special-text marine-special-text" data-animation="">
                Address:  259-10 hillside ave, Glen Oak New York USA
              </div>
              <div className="special-text marine-special-text" data-animation="">
                Mobile:  +1 (516)361-7300 
              </div>
              <div className="special-text marine-special-text" data-animation="">
                Email: <a
                    href="mailto:support@bookmycarnow.com"
                    className="text-decoration-none"
                  >
                    support@bookmycarnow.com
                  </a>
              </div>
            </div>
          </div>
        </div>
      </>
      <MainFooter />
    </>
  );
};
