/** @format */

import { Header } from "../components/Header";
import { Sidebar } from "../components/sidebar";
import { useState } from "react";

const AdminWrapper = () => {
  const [sidebarFull, setSidebarFull] = useState(true);

  // const { pathname } = useLocation();

  const showFullsidebar = () => {
    setSidebarFull(!sidebarFull);
  };

  return (
    <>
      <Header sidebarFull={sidebarFull} />
      <Sidebar showFullsidebar={showFullsidebar} sidebarFull={sidebarFull} />
    </>
  );
};

export default AdminWrapper;
