/** @format */

import instance from "../http";
const prefix = "v1";

export const PostApi = async (url, data, header) => {
  try {
    let result = await instance.post(prefix + url, data, header);
    return result.data;
  } catch (error) {
    if (error) {
      return error;
    }
  }
};

export const GetApi = async (url) => {
  try {
    let result = await instance.get(prefix + url);
    return result.data;
  } catch (error) {
    if (error) {
      return error;
    }
  }
};

export const UpdateApi = async (url,data) => {
  try {
    let result = await instance.put(prefix + url, data);
    return result.data;
  } catch (error) {
    if (error) {
      return error;
    }
  }
};

export const DeleteApi = async (url) => {
  try {
    let result = await instance.get(url);
    return result.data;
  } catch (error) {
    if (error) {
      return error;
    }
  }
};
