/** @format */

import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { MainLayout } from "./Layouts/MainLayout";
import { Login } from "./Auth/Login";
import Register from "./Auth/Register";
import { ContactUs } from "./pages/ContactUs";
import { Dashboard } from "./UserDashboard/Dashboard";
import { AdminDashboard } from "./AdminDashboard/Pages/AdminDashboard";
import { useSelector } from "react-redux";
import AdminWrapper from "./AdminDashboard/Layout/AdminWrapper";
import AdminLogin from "./AdminDashboard/Pages/AdminLogin";
import { AddCar } from "./AdminDashboard/Pages/AddCar";
import { Cars } from "./AdminDashboard/Pages/Cars";
import CreateBooking from "./pages/Vehicle/CreateBooking";
import { Bookings } from "./AdminDashboard/Pages/Bookings";
import { VarifyMail } from "./Auth/VarifyMail";
import Results from "./pages/Results";
import BookNow from "./pages/BookNow";
import PageNotFound from "./pages/PageNotFound";
import RefundPolicy from "./pages/RefundPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Thankyou from "./pages/Thankyou";
import ForgotPassword from "./Auth/ForgotPassword";
import MailConfirm from "./Auth/MailConfirm";
import Resetpassword from "./Auth/ResetPassword";
import Users from "./AdminDashboard/Pages/Users";
import CarDetail from "./pages/CarDetail";

const AllRoutes = () => {
  const { user } = useSelector((state) => state.auth);

  const ProtectedRoutes = ({ children }) => {
    return user && user?.user?.role === "user" ? (
      children
    ) : (
      <Navigate to="/login" />
    );
  };

  const ProtectedAdmin = ({ children }) => {
    return user && user?.user?.role === "admin" ? (
      children
    ) : (
      <Navigate to="/admin-login" />
    );
  };

  const element = useRoutes([
    { path: "/", element: <MainLayout /> },
    { path: "/search", element: <Results /> },
    { path: "/book-now", element: <BookNow /> },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/verifyMail", element: <VarifyMail /> },
    { path: "/contact-us", element: <ContactUs /> },
    { path: "/refund-policy", element: <RefundPolicy /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoutes>
          <Dashboard />
        </ProtectedRoutes>
      ),
    },
    { path: "/createbook", element: <CreateBooking /> },

    //Admin Module
    { path: "/admin-login", element: <AdminLogin /> },
    {
      path: "/admin-dashboard",
      element: (
        <ProtectedAdmin>
          <AdminWrapper />
        </ProtectedAdmin>
      ),
      children: [
        { path: "", element: <AdminDashboard /> },
        { path: "addCar", element: <AddCar /> },
        { path: "cars", element: <Cars /> },
        { path: "bookings", element: <Bookings /> },
        { path: "users", element: <Users /> },
      ],
    },
    { path: "/page-not-found", element: <PageNotFound /> },
    { path: "/thankyou", element: <Thankyou /> },
    { path: "/forgotpassword", element: <ForgotPassword /> },
    { path: "/mailconfirm", element: <MailConfirm /> },
    { path: "/passwordreset", element: <Resetpassword /> },
    { path: "/book-car-detail", element: <CarDetail /> },
    { path: "*", element: <Navigate to="/page-not-found" /> },
  ]);

  return element;
};

export default AllRoutes;
