/** @format */

import React, { useEffect, useRef } from "react";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";
import authservice from "../services/authService";
import { Link, useNavigate } from "react-router-dom";
import {ReactToastSuccess} from '../blocks/Modals/ReactToast'

export const VarifyMail = (props) => {

    // const { user, error, status } = useSelector((state) => state.auth);

    let navigate = useNavigate();
    let code = new URL(window.location.href).searchParams.get("code");
    let uid = new URL(window.location.href).searchParams.get("uid");
    let email = new URL(window.location.href).searchParams.get("email");

    let checkVarication = async() => {
        if(code && uid){
            let res = await authservice.verifyMail(code,uid)
            if(res.status){
                navigate("/login")
            }
        }
    }

    let reSendMail = async(email) =>{
        let res = await authservice.reSendMail(email);
        if(res.status){
            ReactToastSuccess(res.message)
        }
    }

    useEffect(() => {
        checkVarication()
    },[])

    useEffect(() => {
        window.scrollTo(0, 350);
    },[])

    return (
        <>
            <MainHeader />
                <div className="container find-vehicle-container mt-5 mb-5">
                    <div className="row p-5">
                        <div className="col-2">
                        </div>
                        <div className="col-8">
                            <h2 className="text-center">
                                Verify Email to Activate Your Account
                            </h2>
                            <p className="text-center">Please verify your email {email && email} by clicking on the varification link send to you registered email.</p>
                        </div>
                        <div className="text-center">
                            <Link to="/login"> 
                            <button>COMPLETED EMAIL VERIFICATION</button></Link>
                            <br />
                            <button className="mt-3" onClick={()=>reSendMail(email)}>RESEND EMAIL</button>
                        </div>
                        <div className="col-2">
                        </div>
                    </div>
                </div>
            <MainFooter />
        </>
    );
};
