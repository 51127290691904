/** @format */

import React from "react";
import { BsFillGeoAltFill } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import paymentService from "../services/paymentService";
const Main = () => {
  return (
    <>
      <section className="lightGreyBg1">
        <div className="container pt-2">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1 className="text-normal themeBlueColor">
                <span className="themeRedColor">Book your ride -</span> Find,
                Compare & Book
              </h1>
              <h6 className="text-normal themeBlueColor">
                Worldwide Car Transportation via 500+ Trusted Companies
                <BsFillGeoAltFill size={15} className="themeRedColor" />
              </h6>
              <p className="text-muted">VIP Service. Special Airport Rates</p>
              <p className="fw-bold themeRedColor h5 ">
                Book Now <FaArrowRight className="leftRight" />
              </p>
              {/* <NavLink
                className="btn themeBtnRed m-2 my-sm-0 px-4 border-0"
                to="/register">
                Book Now
              </NavLink> */}
            </div>
            <div className="col-md-4">
              <img
                src="/images/paxVan.png"
                alt="Home Page Car | Book My Car Now"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
