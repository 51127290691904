/** @format */

import { GetApi, PostApi } from "./index";;

class authService {
  register({ fname, lname, email,account_type, phone, password }) {
    return PostApi(`/register`, { fname, lname, email,account_type,phone, password });
  }
  login({ email, password }, type) {
    if (type === "admin") {
       return PostApi(`/admin/login`, { email, password });
    } else {
       return PostApi(`/login`, { email, password });
    }
   
  }
  verifyMail(code,uid) {
    return PostApi(`/verifyMail`,{
      code:code,
      uid:uid
    });
  }

  reSendMail(mail) {
    return GetApi(`/reSendMail?mail=${mail}`,);
  }

  forgotPassword(value) {
    return PostApi(`/forgotpassword`, value)
  }

  resetPassword(value) {
    return PostApi(`/resetpassword`, value)
  }

  reCaptcha(value) {
    return PostApi(`/recaptcha/verify`, value)
  }
}

const authservice = new authService();

export default authservice;
