import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { MainHeader } from '../components/MainHeader'
import MainFooter from '../components/MainFooter'
import { ReactToast, ReactToastSuccess } from '../blocks/Modals/ReactToast';
import { useLocation, useNavigate } from 'react-router-dom';
import authservice from '../services/authService';
import { BorderSpinner } from '../blocks/Loaders/BorderSpinner';
import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup'

function Resetpassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [recaptchaToken, setReCaptchaToken] = useState('');
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const ResetpasswordSchema = Yup.object().shape({
        password: Yup.string()
            .required("Enter The Password")
            .min(6, "Min 6 Character Required")
            .max(16, "Max 16 Character Required")
            .matches(/[!@#$%^&*()_+{}[\]:;<>,.?~]/, 'Must contain at least 1 special character')
            .matches(/[a-z]/, 'Must contain at least 1 lowercase letter')
            .matches(/[A-Z]/, 'Must contain at least 1 uppercase letter')
            .matches(/[0-9]/, 'Must contain at least 1 number'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords not match')
            .required('Confirm Password is required')
    });

    if (!token) {
        navigate('/page-not-found');
        return null;
    }

    const captchaChange = (data) => {
        setReCaptchaToken(data)
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        setLoading(true);

        const responce = await authservice.reCaptcha({
            token: recaptchaToken,
        });

        if (responce.success === false) {
            ReactToast("Invalid Captcha");
            setLoading(false);
            setSubmitting(false);
            return;
        }

        const forgotData = {
            password: values.password,
            token: token
        };

        const res = await authservice.resetPassword(forgotData);

        if (res.error) {
            ReactToast("Password is not changed");
            setLoading(false);
        } else {
            ReactToastSuccess("Password Successfully changed");
            navigate('/login');
        }

        setSubmitting(false);
    };

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Reset Password</title>
                <meta
                    name="description"
                    content="Your go-to for car rentals in New York and New Jersey. From Newark Airport to Manhattan, or NY Penn Station to JFK & more, we've got you covered."
                />
                <meta
                    name="keywords"
                    content="car rental in newyork,car rental in new jersey, car rental new york ny,car rental usa new york,car hire new jersey usa,car hire usa new york"
                ></meta>
            </Helmet>

            <MainHeader />

            <div className="container find-vehicle-container m-70">
                <div className="row">
                    <div className="col-md-6 p-5">
                        <div className="container">
                            <div className="row ride-info">
                                <span className="panel-title ml-5">
                                    <span>Reset Password</span>
                                </span>
                            </div>
                        </div>
                        <div className="ors-col-max-width mt-4">
                            <Formik
                                initialValues={{ password: '', confirmPassword: '' }}
                                validationSchema={ResetpasswordSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <Form className='login_form position-relative'>
                                        <div className="pb-4">
                                            <div className="form-group form-group-lg">
                                                <label htmlFor="password" className="label-lg">
                                                    Enter New Password
                                                </label>
                                                <Field
                                                    type="password"
                                                    name="password"
                                                    className="w-100 p-2 border border-gray"
                                                    placeholder="Enter New Password"
                                                />
                                                <ErrorMessage name="password" component="div" className="error" style={{ 'color': '#dc3545' }} />
                                            </div>
                                        </div>

                                        <div className="pb-4">
                                            <div className="form-group form-group-lg">
                                                <label htmlFor="confirmPassword" className="label-lg">
                                                    Enter Confirm Password
                                                </label>
                                                <Field
                                                    type="password"
                                                    name="confirmPassword"
                                                    className="w-100 p-2 border border-gray"
                                                    placeholder="Enter Confirm Password"
                                                />
                                                <ErrorMessage name="confirmPassword" component="div" className="error" style={{ 'color': '#dc3545' }} />
                                            </div>
                                        </div>

                                        <div className='formGroup m-2'>
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                onChange={captchaChange}
                                                className="g-recaptcha"
                                            />
                                        </div>

                                        <div className="form-group mt-2">
                                            <button
                                                type="submit"
                                                className="themeBtnRed btn w-100"
                                                disabled={isSubmitting}
                                            >
                                                {loading ? <BorderSpinner /> : "Reset Password"}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

            <MainFooter />
        </>
    );
}

export default Resetpassword;

