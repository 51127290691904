/** @format */

import { PostApi } from "./index";

class PaymentService {
  createPaymentIntent(data) {
    return PostApi(`/create-payment-intent`, data);
  }
  createBooking(data) {
    return PostApi(`/create-booking`, data);
  }
  updatePaymentIntent(data,id) {
    return PostApi(`/update-payment-intent?id=${id}`, data);
  }

  createPayment(data) {
    return PostApi(`/create-payment`, data);
  }

}

const paymentService = new PaymentService();

export default paymentService;
