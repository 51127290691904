import React from "react";
import { FaCheck, FaClock, FaCreditCard, FaPlaneDeparture, FaTools } from 'react-icons/fa';
const FAQ = () => {
  return (
    <>
    <section id="faq">
        <div className="container">
            <div className="text-left mt-5 mb-3">
                <p className="themeRedColor">FAQ</p>
                <h2 className="text-normal themeBlueColor ">Check out the answers below for the most common questions</h2>
            </div>

            <div className="row">
                <div className="col-md-6 col-lg-4 mb-4">
                    <h6 className="mb-3 text-primary themeBlueColor">
                    <FaPlaneDeparture  className="text-primary pe-2" size="20"/>
                        What airports do you cover?
                    </h6>
                    <p className="text-muted">
                    All major airports.
                    </p>
                </div>
                <div className="col-md-6 col-lg-4 mb-4">
                    <h6 className="mb-3 text-primary themeBlueColor">
                        <FaTools className="text-primary pe-2" size="20"/>
                         Is this service only used for airport trips?
                    </h6>
                    <p className="text-muted">
                        <strong><u> No, we cover everything</u></strong> from local trips to long distance rides.
                    </p>
                </div>
                <div className="col-md-6 col-lg-4 mb-4">
                    <h6 className="mb-3 text-primary themeBlueColor">
                    <FaCreditCard  className="text-primary pe-2" size="20"/>
                        What forms of payment are accepted?
                    </h6>
                    <p className="text-muted">
                    <strong><u>All major credit cards</u></strong> are accepted.
                    </p>
                </div>

                <div className="col-md-6 col-lg-4 mb-4">
                    <h6 className="mb-3 text-primary themeBlueColor">
                    <FaClock  className="text-primary pe-2" size="20"/>
                     What are your hours of operation?
                    </h6>
                    <p className="text-muted">
                    We operate 24/7.
                    </p>
                </div>

                <div className="col-md-6 col-lg-4 mb-4">
                    <h6 className="mb-3 text-primary themeBlueColor">
                    <FaCheck className="text-primary pe-2" size="20"/>
                    How Will I know that my ride is booked?
                    </h6>
                    <p className="text-muted">
                        You will <strong><u>receive a confirmation email</u></strong> as well as text updates regarding your trip.
                    </p>
                </div>

                {/* <div className="col-md-6 col-lg-4 mb-4">
                    <h6 className="mb-3 text-primary themeBlueColor"><i className="fas fa-book-open text-primary pe-2"></i> Another
                        question that is longer than usual</h6>
                    <p className="text-muted">
                        Of course! We`re happy to offer a free plan to anyone who wants to try our service.
                    </p>
                </div> */}
            </div>
        </div>
    </section>
    {/* <section>
        <div className="container">
          <div className="row align-items-center my-5">
            <div className="col-md-6">
              <img
                src="/images/paxVan.png"
                alt=""
                className="w-100"
              />
            </div>
            <div className="col-md-6">
              <p className="themeRedColor">Our App</p>
              <h2 className="text-uppercase themeBlueColor">
                One App, Millions Of Users
              </h2>
              <p className="text-muted">
                {" "}
                Hello it is Text FeildHello it is Text FeildHello it is Text
                FeildHello it is Text FeildHello it is
              </p>
            </div>
          </div>
        </div>
      </section> */}
    </>
    );
  };
  
  export default FAQ;