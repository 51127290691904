/** @format */

import React from "react";
import {BsCashCoin} from "react-icons/bs";
import {AiFillCar} from "react-icons/ai";
import {FaDollarSign, FaVirus, FaGlobe, FaHeadset} from "react-icons/fa";

const Testimonials = () => {
  return (
    <>
    
    {/* <section id="partners">
        <div className="container">
          <div className="text-center mt-5">
            <p className="themeRedColor">Our Trusted Partners</p>
          </div>
          <div className="row">
            <img
              src="/images/brand.png"
              alt=""
              className="img-responsive w-100"
            />
          </div>
        </div>
      </section> */}
      <section className="lightGreyBg1 mt-5 pt-3 pb-3" id="service">
        <div className="container">
          <div className="text-center">
            <p className="themeRedColor">Why Choose Us</p>
            <h2 className="text-uppercase themeBlueColor"> </h2>
          </div>
          <div className="row">
            <div className="col-md-4 flex-column">
              <img 
              src="/images/Book_My_Car_Now_Banner.gif"
              alt="Book My Car Now Promotion Banner"
              className="pe-4"
              />
              {/* <h3 className="text-normal themeBlueColor">
                What People Say About Us
              </h3>
              <p className="text-muted">
                {" "}
                Hello it is Text FeildHello it is Text FeildHello it is Text
                FeildHello it isText FeildHello it is
              </p>
              <div className=" align-items-end text-start">
                <NavLink
                  className="themeBtnWhite text-decoration-none px-3 py-2 rounded-2"
                  to="#testimonial-carousel"
                  role="button"
                  data-bs-slide="prev">
                  <i className="bi bi-arrow-left"></i>
                </NavLink>
                <NavLink
                  className="themeBtnWhite text-decoration-none m-4 px-3 py-2 rounded-2"
                  to="#testimonial-carousel"
                  role="button"
                  data-bs-slide="next">
                  <i className="bi bi-arrow-right"></i>
                </NavLink>
              </div>

              <div
                id="testimonial-carousel"
                className="carousel slide mt-3"
                data-bs-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="testimonial-box p-3 rounded-2">
                      <small>
                        Lorem ipsum dolor sit amet, consectetur adipiscingLorem
                        ipsum dolor sit amet, consectetur adipiscingLorem ipsum
                        dolor sit amet, consectetur adipiscingLorem ipsum dolor
                        sit amet, consectetur adipiscingLorem ipsum dolor sit
                        amet, consectetur adipiscingLorem
                      </small>
                      <div className="pt-3 ">
                        <small className="rating text-muted ">
                           <FaStar size={15} className="text-warning"/>
                           <FaStar size={15} className="text-warning"/>
                           <FaStar size={15} className="text-warning"/>
                           <FaStar size={15} className="text-warning"/>
                           <FaStar size={15} className="text-warning"/>{" "}
                        </small>
                      </div>
                    </div>
                    <div className=" row align-items-center p-3 text-start d-flex justify-content-center">
                      <div className="col-sm-4">
                        <img
                          src="/images/paxVan.png"
                          alt="Testimonial 1"
                          className="w-100 bg-dark rounded-circle"
                        />
                      </div>
                      <div className="col-sm-8">
                        <h6 className="themeBlueColor">John Doe</h6>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="testimonial-box p-3 rounded-2">
                      <small>
                        Lorem ipsum dolor sit amet, consectetur adipiscingLorem
                        ipsum dolor sit amet, consectetur adipiscingLorem ipsum
                        dolor sit amet, consectetur adipiscingLorem ipsum dolor
                        sit amet, consectetur adipiscingLorem ipsum dolor sit
                        amet, consectetur adipiscingLorem
                      </small>
                      <div className="pt-3 ">
                        <small className="rating text-muted ">
                           <FaStar size={15} className="text-warning"/>
                           <FaStar size={15} className="text-warning"/>
                           <FaStar size={15} className="text-warning"/>
                           <FaStar size={15} className="text-warning"/>
                           <FaStar size={15} className="text-warning"/>{" "}
                        </small>
                      </div>
                    </div>
                    <div className=" row align-items-center p-3 text-start d-flex justify-content-center">
                      <div className="col-sm-4">
                        <img
                          src="/images/paxVan.png"
                          alt="Testimonial 1"
                          className="w-100 bg-dark rounded-circle"
                        />
                      </div>
                      <div className="col-sm-8">
                        <h6 className="themeBlueColor">John Doe</h6>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="testimonial-box p-3 rounded-2">
                      <small>
                        Lorem ipsum dolor sit amet, consectetur adipiscingLorem
                        ipsum dolor sit amet, consectetur adipiscingLorem ipsum
                        dolor sit amet, consectetur adipiscingLorem ipsum dolor
                        sit amet, consectetur adipiscingLorem ipsum dolor sit
                        amet, consectetur adipiscingLorem
                      </small>
                      <div className="pt-3 ">
                        <small className="rating text-muted ">
                           <FaStar size={15} className="text-warning"/>
                           <FaStar size={15} className="text-warning"/>
                           <FaStar size={15} className="text-warning"/>
                           <FaStar size={15} className="text-warning"/>
                           <FaStar size={15} className="text-warning"/>{" "}
                        </small>
                      </div>
                    </div>
                    <div className=" row align-items-center p-3 text-start d-flex justify-content-center">
                      <div className="col-sm-4">
                        <img
                          src="/images/paxVan.png"
                          alt="Testimonial 1"
                          className="w-100 bg-dark rounded-circle"
                        />
                      </div>
                      <div className="col-sm-8">
                        <h6 className="themeBlueColor">John Doe</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* Add more carousel items for additional testimonials  */}
            </div>
            <div className="col-md-8">
              <h3 className="text-normal themeBlueColor">
                What makes "Book My Car Now" the best!
              </h3>
              <div className="card">
                <div className="card-block">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="media py-3">
                        <BsCashCoin size={60} className="vc-icon-feat p-2 m-2 h1 rounded-3" />
                        <div className="media-body align-self-center">
                          <h3 className="h5">Lowest Price Guarantee</h3>
                          <small>
                            The most affordable car rentals are waiting for you.
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="media py-3">
                      <AiFillCar size={60} className="vc-icon-feat p-2 m-2 h1 rounded-3" />
                        <div className="media-body align-self-center">
                          <h3 className="h5">Free Cancellation</h3>
                          <small>
                            Cancel for free, 24 hours before the pick up.
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="media py-3">
                      <FaDollarSign size={60} className="vc-icon-feat p-2 m-2 h1 rounded-3" />
                        <div className="media-body align-self-center">
                          <h3 className="h5">All Inclusive Pricing</h3>
                          <small>
                            Our prices include CDW, Theft Waiver, airport
                            surcharge &amp; taxes.
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="media py-3">
                      <FaVirus size={60} className="vc-icon-feat p-2 m-2 h1 rounded-3" />
                        <div className="media-body align-self-center">
                          <h3 className="h5">No Hidden Charges</h3>
                          <small>
                            No more payments after you pay. No last minute
                            surprises or costs.
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="media py-3">
                      <FaGlobe size={60} className="vc-icon-feat p-2 m-2 h1 rounded-3" />
                        <div className="media-body align-self-center">
                          <h3 className="h5">Leading Suppliers Worldwide</h3>
                          <small>
                            International &amp; domestic car rental brands like
                            Hertz, Alamo, Avis etc.
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="media py-3">
                      <FaHeadset size={60} className="vc-icon-feat p-2 m-2 h1 rounded-3" />
                        <div className="media-body align-self-center">
                          <h3 className="h5">Dedicated Customer Support</h3>
                          <small>
                            24/7 excellent customer support on phone, chat &amp;
                            email.
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
