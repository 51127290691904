/** @format */

import React, { useEffect, useRef, useState } from "react";
import MainFooter from "../components/MainFooter";
import { MainHeader } from "../components/MainHeader";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { clearError, requestToRegister } from "./authSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReactToast } from "../blocks/Modals/ReactToast";
import { BorderSpinner } from "../blocks/Loaders/BorderSpinner";
import authservice from "../services/authService";
import { Helmet } from "react-helmet-async";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
  const { user, error, status } = useSelector((state) => state.auth);

  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [recaptchaToken, setReCaptchaToken] = useState();

  const RegisterSchema = Yup.object().shape({
    fname: Yup.string().required("Enter Your First Name"),
    lname: Yup.string().required("Enter Your Last Name"),
    email: Yup.string().email().required("Enter Your Email"),
    phone: Yup.string().required("Enter Your Phone").min(10, "Min 10 required").max(10, "Max 10 required"),
    password: Yup.string().required("Enter The Password")
      .min(6, "Min 6 Character Required")
      .max(16, "Max 16 Character Required")
      .matches(/[!@#$%^&*()_+{}[\]:;<>,.?~]/, 'Must contain at least 1 special character')
      .matches(/[a-z]/, 'Must contain at least 1 lowercase  letter')
      .matches(/[A-Z]/, 'Must contain at least 1 uppercase  letter')
      .matches(/[0-9]/, 'Must contain at least 1 number')
  });

  const values = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    password: "",
    account_type: "0",
  };

  const captchaChange = (data) => {
    setReCaptchaToken(data)
  }


  const handleSubmit = async (values) => {

    const responce = await authservice.reCaptcha({
      token: recaptchaToken,
    })

    if (responce.success === false) {
      ReactToast("invalid Captcha")
      return false;
    }

    const res = await authservice.register(values);
    if (res.status) {
      navigate(`/verifyMail?email=${values.email}`);
    }else{
      ReactToast(res.message)
    }
    // dispatch(requestToRegister(values));
  };

  useEffect(() => {
    if (error) {
      ReactToast(error);
      dispatch(clearError());
    }
  }, [error]);

  useEffect(() => {
    if (user == null) {
      navigate("/register");
    } else {
      navigate("/dashboard");
    }
  }, [user]);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Register Now! | Car Rental in Ny & NJ | Airport Transportation</title>
        <meta
          name="description"
          content="Your go-to for car rentals in NY & NJ. From Newark Airport to Manhattan, or NY Penn Station to JFK & more, we've got you covered.Register Now!"
        />
        <link rel="canonical" href={`${process.env.REACT_APP_URL}login`} />
        <meta
          name="keywords"
          content="car rental in newyork,car rental in new jersey, car rental new york ny,car rental usa new york,car hire new jersey usa,car hire usa new york"
        ></meta>
      </Helmet>
      <MainHeader />
      <div className="container find-vehicle-container m-70">
        <div className="row">
          <div className="col-md-6 p-5">
            <div className="container">
              <div className="row ride-info">
                <span className="panel-title ml-5">
                  <span>Registration</span>
                </span>
              </div>
            </div>
            <div className="ors-col-max-width mt-4">
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={values}
                validationSchema={RegisterSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form className="login_form position-relative">
                    <div className="form-group form-group-lg">
                      <label htmlFor="fname" className="label-lg">
                        First Name
                      </label>
                      <Field
                        type="text"
                        name="fname"
                        placeholder="First Name"
                        id="fname"
                        tabIndex="4"
                        autoFocus=""
                        className={`The-form-select  ${touched.fname && errors.fname
                            ? "is-invalid form-control"
                            : ""
                          }`}
                      />
                      {errors.fname && touched.fname ? (
                        <div className="d-block invalid-feedback ">
                          {errors.fname}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group form-group-lg mt-4">
                      <label htmlFor="lname" className="label-lg">
                        Last Name
                      </label>
                      <Field
                        type="text"
                        name="lname"
                        placeholder="Last Name"
                        id="lname"
                        tabIndex="4"
                        autoFocus=""
                        autoComplete="email"
                        className={`The-form-select  ${touched.lname && errors.lname
                            ? "is-invalid form-control"
                            : ""
                          }`}
                      />
                      {errors.lname && touched.lname ? (
                        <div className="d-block invalid-feedback ">
                          {errors.lname}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group form-group-lg mt-4">
                      <label htmlFor="email" className="label-lg">
                        Email Address
                      </label>
                      <Field
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        id="email"
                        tabIndex="4"
                        autoFocus=""
                        autoComplete="email"
                        className={`The-form-select  ${touched.email && errors.email
                            ? "is-invalid form-control"
                            : ""
                          }`}
                      />
                      {errors.email && touched.email ? (
                        <div className="d-block invalid-feedback ">
                          {errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group form-group-lg mt-4">
                      <label htmlFor="account_type" className="label-lg">
                        Account Type
                      </label>
                      <Field
                        type="text"
                        as="select"
                        name="account_type"
                        className={`The-form-select`}
                      >
                        <option value="0" defaultValue>
                          personal
                        </option>
                        <option value="1">corporate</option>
                      </Field>
                    </div>
                    <div className="form-group form-group-lg mt-4">
                      <label htmlFor="phone" className="label-lg">
                        Mobile Phone
                      </label>
                      <Field
                        type="text"
                        name="phone"
                        placeholder="(555) 555-5555"
                        id="phone"
                        tabIndex="4"
                        autoFocus=""
                        autoComplete="email"
                        className={`The-form-select  ${touched.phone && errors.phone
                            ? "is-invalid form-control"
                            : ""
                          }`}
                      />
                      {errors.phone && touched.phone ? (
                        <div className="d-block invalid-feedback ">
                          {errors.phone}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group form-group-lg mt-4">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label htmlFor="password-login" className="label-lg">
                          Password
                        </label>
                      </div>
                      <Field
                        type="password"
                        name="password"
                        id="password-login"
                        tabIndex="5"
                        autoComplete="current-password"

                        className={`The-form-select  ${touched.password && errors.password
                            ? "is-invalid form-control"
                            : ""
                          }`}
                      />
                      {errors.password && touched.password ? (
                        <div className="d-block invalid-feedback ">
                          {errors.password}
                        </div>
                      ) : null}

                      <div className='formGroup m-2'>
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={captchaChange}
                          className="g-recaptcha"
                        />
                      </div>

                      <div className="form-group mt-4">
                        <button
                          type="submit"
                          disabled={status == "loading" ? true : false}
                          className="themeBtnRed btn w-100"
                        >
                          {status == "loading" ? (
                            <BorderSpinner />
                          ) : (
                            "Create Account"
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="text-center mt-3">
              I already have an account? {" "}
              <NavLink to="/login" className="text-danger" >Log in Now</NavLink>
            </div>
          </div>
          <div className="col-md-6 p-5 mb-4">
            <div className="container">
              <div className="row ride-info">
                <span className="panel-title ml-5">
                  <span>Benefits of Creating an Account</span>
                </span>
              </div>
            </div>
            <div className="ors-col-max-width mt-5">
              <ul className="ors-list-default">
                <li>Book rides even faster using stored account details.</li>
                <li>Modify trip details.</li>
                <li>Access invoices and payment receipts.</li>
                <li>Reporting tools.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default Register;
