/** @format */

import React, { useState, useEffect } from "react";
import {BsFillGeoAltFill} from "react-icons/bs"
import {FaCalendarAlt, FaClock} from "react-icons/fa"
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, DatePicker, TimePicker, AutoComplete } from "antd";
import dayjs from "dayjs";
import {
  setPickUpAddress,
  setDropOffAddress,
  setPickUpDate,
  setPickUpTime,
  setPickUpOption,
  setDropOffOption,
  setExtraData,
  setCars,
  setLoading,
} from "./formSlice";
import bookingService from "../services/BookingService";
import { isSearch, searchNewVehicle } from "../pages/Vehicle/VehicleSlice";
import globalService from "../services/globalService";

const Flatrate = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [places, setPlaces] = useState([]);
  const {
    pickUpAddress,
    dropOffAddress,
    pickUpDate,
    pickUpTime,
    pickUpOption,
    dropOffOption,
    loading,
  } = useSelector((state) => state.formData);

  useEffect(() => {
    if (location.pathname === "/") {
      dispatch(setPickUpOption(""));
      dispatch(setDropOffOption(""));
    }
  }, []);

  const initialValues = {
    pickUpAddress: location.pathname === "/search" ? pickUpAddress : "",
    dropOffAddress: location.pathname === "/search" ? dropOffAddress : "",
    pickUpDate:
      location.pathname === "/search" && pickUpDate ? dayjs(pickUpDate) : "",
    pickUpTime:
      location.pathname === "/search" && pickUpTime
        ? dayjs(pickUpTime, "hh:mm:ss")
        : "",
  };

  const fetchCars = async (sendData) => {
    let res = await bookingService.findVehicle(sendData);
    if (res.status && res.result.length > 0) {
      dispatch(setCars(res.result));
      dispatch(searchNewVehicle(sendData));
      dispatch(isSearch());
      dispatch(setLoading(false));
    } else {
      dispatch(setCars(""));
      console.log("no rates found");
      dispatch(setLoading(false));
    }
  };

  const onFinish = async (values) => {
    dispatch(setLoading(true));
    dispatch(setPickUpAddress(values?.pickUpAddress));
    dispatch(setDropOffAddress(values?.dropOffAddress));
    dispatch(
      setPickUpDate(
        new Date(values?.pickUpDate).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })
      )
    );
    dispatch(
      setPickUpTime(
        new Date(values?.pickUpTime).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      )
    );
    dispatch(
      setExtraData({
        flight: "",
        luggage_count: "1",
        no_of_passenger: "1",
      })
    );
    const sendData = {
      ServiceTypeId: "Flatrate",
      pickUpAddress: values?.pickUpAddress,
      dropOffAddress: values?.dropOffAddress,
      pickUpDate: new Date(values?.pickUpDate).toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
      pickUpTime: new Date(values?.pickUpTime).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }),
      pickUpLat: pickUpOption?.lat,
      pickUpLon: pickUpOption?.lon,
      dropOffLat: dropOffOption?.lat,
      dropOffLon: dropOffOption?.lon,
      luggage_count: "1",
      no_of_passenger: "1",
    };

    fetchCars(sendData);
    Navigate("/search");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleSearch = async(value) => {
    setPlaces([]);
    let res = await globalService.getPlaces(value);
    setPlaces(res);
    // dispatch(fetchOptions(value));
  };

  return (
    <>
      <Form
        layout="vertical"
        name="flatrate"
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        id="flatrate"
      >
        <div className="row align-items-center mx-3 py-3 f_shadow bg-white position-relative rounded-3">
          <div className="col-12 col-md-3 form-group my-3  border-end d-flex justify-content-middle align-items-center">
          <BsFillGeoAltFill size={27} className="themeRedColor p-1" />
            <Form.Item
              name="pickUpAddress"
              className="m-0 w-100"
              rules={[
                {
                  required: true,
                  message: "Please Choose Your Pick Up Address",
                },
              ]}
            >
              <AutoComplete
                options={places.map((option) => ({
                  ...option,
                  disabled: option.value === dropOffOption?.value,
                }))}
                onSearch={(value) => { value.length >= 3 && handleSearch(value)}}
                onSelect={(value, option) => dispatch(setPickUpOption(option))}
                className="w-100"
                placeholder="Pick Up Address"
              ></AutoComplete>
            </Form.Item>
          </div>
          <div className="col-12 col-md-3 form-group my-3 border-end d-flex justify-content-middle align-items-center">
          <BsFillGeoAltFill size={27} className="themeRedColor p-1" />
            <Form.Item
              name="dropOffAddress"
              className="m-0 w-100"
              rules={[
                {
                  required: true,
                  message: "Please Choose Your Drop Off Address",
                },
                {},
              ]}
            >
              <AutoComplete
                options={places.map((option) => ({
                  ...option,
                  disabled: option.value === pickUpOption?.value,
                }))}
                onSearch={(value) => { value.length >= 3 && handleSearch(value)}}
                onSelect={(value, option) => dispatch(setDropOffOption(option))}
                placeholder="Drop Off Address"
                className="w-100"
              ></AutoComplete>
            </Form.Item>
          </div>
          <div className="col-6 col-md-2 form-group my-2 border-end d-flex justify-content-middle align-items-center">
          <FaCalendarAlt size={27} className="themeRedColor p-1" />
            <Form.Item
              name="pickUpDate"
              id="date"
              className="m-0"
              rules={[
                {
                  required: true,
                  message: "Please Choose Pick Up Date!",
                },
              ]}
            >
              <DatePicker
                className="w-100"
                placeholder="Pick Up Date"
                disabledDate={(d) => d && d < dayjs().startOf("day")}
              />
            </Form.Item>
          </div>
          <div className="col-6 col-md-2 form-group my-2 border-end d-flex justify-content-middle align-items-center">
          <FaClock size={27} className="themeRedColor p-1" />
            <Form.Item
              name="pickUpTime"
              id="time"
              className="m-0"
              rules={[
                {
                  required: true,
                  message: "Please Choose Pick Up Time",
                },
              ]}
            >
              <TimePicker className="w-100" placeholder="Pick Up Time" />
            </Form.Item>
          </div>
          <div className="col-6  col-md-2 form-group my-2">
            <Form.Item className="w-50 m-0">
              <Button
                htmlType="submit"
                className="btn themeBtnRed px-4 py-0"
                loading={loading}
              >
                Search
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </>
  );
};

export default Flatrate;
