import React from 'react'
import { toast } from "react-toastify";

export const ReactToast = (error) => {
  toast.error(error, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2500,
  });
}

export const ReactToastSuccess = (msg) => {
  toast(msg, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 1500,
  });
};