/** @format */

import "../node_modules/bootstrap/dist/js/bootstrap.js";
import logo from "./logo.svg";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AllRoutes from "./AllRoutes";
import { toast } from "react-toastify";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export * from "./store";

toast.configure();

function App() {
  const { pathname } = useLocation();
  let dashboard = pathname.includes("/admin");
  useEffect(() => {
    if (dashboard) {
      document.body.style.background = "#F8F8F8";
    }
  }, [pathname]);

  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
