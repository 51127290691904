import React, { useEffect, useRef } from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { clearError, requestToLogin } from '../../Auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactToast } from '../Modals/ReactToast';
import { BorderSpinner } from '../Loaders/BorderSpinner';


const LoginPopUp = () => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, error, status } = useSelector((state) => state.auth);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required("Enter Your Email"),
    password: Yup.string().required("Enter The Password"),
  });

  const values = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values) => {
    dispatch(requestToLogin(values));
    if (user === null) {
      return false
    }
  }

  useEffect(() => {
    if (user !== null) {
      navigate('/book-now')
    }
  }, [user, navigate])

  useEffect(() => {
    if (error) {
      ReactToast(error);
      dispatch(clearError());
    }
  }, [error, dispatch]);


  return (
    <>
      <div className="login_form">
        <div className="text-center">
          <h3>Login User</h3>
        </div>
        <div className="form-data">
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={values}
            validationSchema={LoginSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="form-group form-group-lg">
                  <label htmlFor="username-login" className="label-lg">
                    Email Address / Username
                  </label>
                  <Field
                    type="text"
                    id="username-login"
                    name="email"
                    tabIndex="4"
                    autoFocus=""
                    autoComplete="email"
                    className={`The-form-select  ${touched.email && errors.email
                      ? "is-invalid form-control"
                      : ""
                      }`}
                  />
                  <div className="d-flex justify-content-between">
                    <label htmlFor="username-login" className="label-lg mt-3">
                      Password
                    </label>
                    <label htmlFor="username-login" className="label-lg mt-3">
                      <NavLink
                        to="/forgotpassword"
                        id="forgotPassLink"
                        className="text-danger"
                      >
                        Forgot password?
                      </NavLink>
                    </label>
                  </div>
                  <Field
                    type="password"
                    id="password-login"
                    name="password"
                    tabIndex="5"
                    autoComplete="current-password"
                    className={`The-form-select  ${touched.password && errors.password
                      ? "is-invalid form-control"
                      : ""
                      }`}
                  />
                </div>
                <div className="form-group mt-4">
                  <button type="submit" className="themeBtnRed btn w-100">
                    {status === "loading" ? <BorderSpinner /> : "Log In"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="text-center mt-3">
          Don't have an account?{" "}
          <NavLink to="/register" className="text-danger">Register Now</NavLink>
        </div>
      </div>

    </>
  )
}

export default LoginPopUp