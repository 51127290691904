import React, { useState } from 'react'
import { MainHeader } from '../components/MainHeader'
import MainFooter from '../components/MainFooter'
import { Login } from '../Auth/Login'
import { Divider } from 'antd'
import { useSelector } from 'react-redux'
const Thankyou = () => {

  const { user } = useSelector((state) => state.auth);

  const data = localStorage.getItem("bookingDetail") ? JSON.parse(localStorage.getItem("bookingDetail")) : null;

  const [{ fname, lname, phone, email }] = data.passengersData;
  const fare = Number(data.fare)
  const toll = Number(data.toll)
  const isLuxury = data.isLuxury === true ? Math.round(fare * 0.18) : 0;

  return (
    <>
      <MainHeader />

      <h1 className='text-center mx-1 mb-5'>Thankyou for the booking ride</h1>

      {!data && !user || (user?.user.role !== "user") ?
        <Login fake={true} />
        : (

          <div id='thankyou' className="row detail-info ml-1 mb-5 rounded">
            <span className="panel-title ml-2">
              <h3 className="panelHeadTitle fs-5 mb-3">
                Passenger Information
              </h3>
            </span>

            <div className="passengers">
              <div id='name-pass' className="pass sec-name">
                <div className="detail fname">
                  <div className="" id="fnameHolder">
                    <span> First Name: </span>
                    <p className=''> {fname}</p>
                  </div>
                </div>
                <div className="detail lname">
                  <div className="input-group d-flex gap-2" id="fnameHolder">
                    <span>Last Name: </span>
                    <p>{lname}</p>
                  </div>
                </div>
              </div>

              <div className="pass sec-email">
                <div className="detail email-detail">
                  <div className="input-group d-flex gap-2 email-label" id="fnameHolder">
                    <span> email: </span>
                    {email}
                  </div>
                </div>
                <div className="detail phone-det">
                  <div className="input-group d-flex gap-2" id="fnameHolder">
                    <span>Phone No: </span>
                    {phone}
                  </div>
                </div>
              </div>
            </div>



            <Divider />
            <br /><br />

            <div id='infi' className=" d-flex gap-5">

              <div id='infi-child' className="justify-content-between">
                <div>
                  <h5>Pickup address: </h5>
                  <p>{data.pickUpAddress}</p>
                </div>
                <div className='address'>
                  <h5 className=''>Dropoff address: </h5>
                  <p>{data.dropOffAddress}</p>
                </div>
              </div>

              <div className="payment mb-3">
                <h4>Payment Detail: </h4>
                <div className="amount">
                  <div className="amount-name">
                    <h6>Fare</h6>
                    <h6>Toll</h6>
                    <h6>Luxury Service Fee</h6>
                  </div>
                  <div className="amount-detail">
                    <h6>${Math.round(fare ? fare : 0)}</h6>
                    <h6>${Math.round(toll ? toll : 0)}</h6>
                    <h6>${Math.round(isLuxury)}</h6>
                  </div>
                </div>
                <div className="total-amount rounded">
                  <h5 className='fw-bold'>
                    Total
                  </h5>
                  <h6 className='fw-light'>
                    ${Math.round(toll + fare + isLuxury)}</h6>
                </div>
              </div>
            </div>
          </div >

        )}

      <MainFooter />
    </>
  )
}

export default Thankyou