/** @format */

import React, { useEffect, useState } from "react";
// import { TbCarOff } from "react-icons/tb";
import { useDispatch } from "react-redux";
import BookingDialog from "../../blocks/Dialogs/BookingDialog";
import { openBookingDialog } from "../../pages/Vehicle/VehicleSlice";
import adminService from "../../services/adminService";
import { ReactToast, ReactToastSuccess } from "../../blocks/Modals/ReactToast";
import Pagination from "@mui/material/Pagination";
import { FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";

export const Bookings = () => {
  const [booking, setBooking] = useState([]);
  const [bookingDialogData, setBookingDialogData] = useState([]);
  const [pageApi, setPageApi] = useState(1);
  const [sortKey, setSortKey] = useState("pickup_date");
  const [sortOrder, setSortOrder] = useState("desc");
  const dispatch = useDispatch();
  const handleSort = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };
  const query = `page=${pageApi}&sortKey=${sortKey}&sortOrder=${sortOrder}`
  let getBookings = async () => {
    let res = await adminService.getBookings(query);
    if (res.status) {
      setBooking(res);
    }
  };

  let updateStatus = async (status, id) => {
    const changeStatus = window.confirm("Are You Sure To Change The Status ?")
    if (!changeStatus) {
      return false;
    }
    let res = await adminService.updateBookingStatus(status, id);
    if (res.status) {
      getBookings(query);
      ReactToastSuccess(res.message);
    } else {
      ReactToast("opps somethig went wrong");
    }
  };

  useEffect(() => {
    getBookings();
  }, [query]);

  return (
    <>
      <BookingDialog booking={bookingDialogData} />
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-12 mt-3 table-responsive">
            <table className="table ac-data-table ac-table-collapse ac-table-primary ac-table-responsive">
              <thead className="active">
                <tr id="ridesTableHead">
                  <th className="sortable number text-center">
                    {/* <input
                      type="checkbox"
                      name="select-all-rides"
                      // onclick="Ores4Rides.SelectAllRides(this);"
                    /> */}
                    <span
                      title="Sort By Confirmation Number"
                    // onclick="Ores4Rides.OnTableHeadClick($(this).parent(), 'ConfNumber');"
                    >
                      Conf. #
                    </span>
                  </th>
                  <th
                    className="sortable active descending text-center"
                    onClick={() => handleSort("pickup_date")}
                          >
                            {sortKey === "pickup_date" ? (
                              sortOrder === "desc" ? (
                                <FaSortAlphaUp />
                              ) : (
                                <FaSortAlphaDown />
                              )
                            ) : null}
                    <span title="Sort By Date">Date</span>
                  </th>
                  <th
                    className="sortable active descending text-center"
                    onClick={() => handleSort("pickup_time")}
                    >
                      {sortKey === "pickup_time" ? (
                        sortOrder === "desc" ? (
                          <FaSortAlphaUp />
                        ) : (
                          <FaSortAlphaDown />
                        )
                      ) : null}
                    <span title="Sort By Time">Time</span>
                  </th>
                  <th
                    className="sortable text-center"
                    onClick={() => handleSort("Passenger")}
                    >
                      {sortKey === "Passenger" ? (
                        sortOrder === "desc" ? (
                          <FaSortAlphaUp />
                        ) : (
                          <FaSortAlphaDown />
                        )
                      ) : null}
                    <span title="Sort By Passenger">Passenger</span>
                  </th>
                  <th className="text-center"
                  onClick={() => handleSort("pickup_address")}
                  >
                    {sortKey === "pickup_address" ? (
                      sortOrder === "desc" ? (
                        <FaSortAlphaUp />
                      ) : (
                        <FaSortAlphaDown />
                      )
                    ) : null}
                    <span>Pickup Address</span>
                  </th>
                  <th className="text-center"
                  onClick={() => handleSort("dropoff_address")}
                    >
                      {sortKey === "dropoff_address" ? (
                        sortOrder === "desc" ? (
                          <FaSortAlphaUp />
                        ) : (
                          <FaSortAlphaDown />
                        )
                      ) : null}
                    <span>Drop Off Address</span>
                  </th>
                  <th
                    className="sortable text-center"
                    onClick={() => handleSort("status")}
                    >
                      {sortKey === "status" ? (
                        sortOrder === "desc" ? (
                          <FaSortAlphaUp />
                        ) : (
                          <FaSortAlphaDown />
                        )
                      ) : null}
                    <span title="Sort By Status">Status</span>
                  </th>
                  <th
                    className="sortable total text-center"
                    onClick={() => handleSort("fare")}
                    >
                      {sortKey === "fare" ? (
                        sortOrder === "desc" ? (
                          <FaSortAlphaUp />
                        ) : (
                          <FaSortAlphaDown />
                        )
                      ) : null}
                    <span title="Sort By Total">Total</span>
                  </th>
                  <th className="actions text-center">
                    <span>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody id="tableRides">
                {booking.rides && booking.rides.length > 0 ? (
                  booking.rides.map((el, i) => (

                    <tr
                      id="ridesTableHead"
                      style={{
                        color: el.status === "cancel" ? "red" : null,
                      }}
                      key={i}
                    >
                      <td className="sortable number text-center">
                        <span title="Sort By Confirmation Number">
                          {i + 1}
                        </span>
                      </td>
                      <td className="sortable active descending text-center">
                        <span title="Sort By Date">{el.pickup_date}</span>
                      </td>
                      <td className="sortable active descending text-center">
                        <span title="Sort By Time">{el.pickup_time}</span>
                      </td>
                      <td className="sortable text-center">
                        <span title="Sort By Passenger">{el.Passenger}</span>
                      </td>
                      <td className="text-start">
                        <span>{el.pickup_address}</span>
                      </td>
                      <td className="text-start">
                        <span>{el.dropoff_address}</span>
                      </td>
                      <td className="sortable text-center">
                        <select
                          className="admin-status-select"
                          onChange={(e) =>
                            updateStatus(e.target.value, el.id)
                          }
                          style={{
                            textTransform: "capitalize",
                            color: el.status === "cancel" ? "red" : null,
                          }}
                          value={el.status}
                        >
                          <option value={el.status}>{el.status}</option>
                          <option
                            value={el.status === "new" ? "Confirm" : "new"}
                          >
                            {el.status === "new" ? "Confirm" : "new"}
                          </option>
                        </select>
                      </td>
                      <td>
                        <span title="Sort By Total">${el.fare}</span>
                      </td>
                      <td className="actions text-center">
                        <span>
                          <button
                            onClick={() => {
                              setBookingDialogData(el);
                              dispatch(openBookingDialog());
                            }}
                            className="btn themeBtnWhite bg-white"
                          >
                            {/* <TbCarOff /> */}
                            Details
                          </button>
                        </span>
                      </td>
                    </tr>

                  ))
                ) : (
                  <tr>
                    <td colSpan="9">
                      <div className="content-empty text-center">
                        <span className="text-muted">No Booking available</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-2 mb-4">
        <Pagination
          count={booking.pages}
          onChange={(e, value) => setPageApi(value)}
          color="primary"
        />
      </div>
    </>
  );
};
