import React, { useState } from 'react'
import { MainHeader } from '../components/MainHeader'
import { Carousel } from "react-bootstrap";
import { FaRegHeart, FaGasPump, FaFan, FaStar, FaStarHalf } from "react-icons/fa";
import { MdAirlineSeatReclineExtra, MdOutlineLuggage, MdOutlineHealthAndSafety, MdRadio, MdBluetoothAudio, MdOutlineGpsFixed, MdOutlineLinkedCamera } from "react-icons/md";
import { TiTick, } from "react-icons/ti";
import { GiGearStickPattern, GiLindenLeaf, GiSteeringWheel, GiSpeaker, GiCarWheel } from "react-icons/gi";
import { CiCreditCard2, CiParking1 } from "react-icons/ci";
import { IoBuild } from "react-icons/io5";
import MainFooter from '../components/MainFooter';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import BookingProcessDialog from '../blocks/Dialogs/BookingProcessDialog';
import { useSelector } from 'react-redux';
import LoginPopUp from '../blocks/Dialogs/LoginPopUp';

const CarDetail = () => {
  const rating = Math.floor(Math.random() * 9) + 1;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const showLogin = () => setIsLoginOpen(true);
  const CancelLogin = () => setIsLoginOpen(false);

  const { user } = useSelector((state) => state.auth)
  const { curruntVehicle, searchVehicle } = useSelector((state) => state.vehicle)

  let car_features;
  try {
    car_features = JSON.parse(curruntVehicle.car_features);
  } catch (error) {
    car_features = ["- No Car Features Details available -"];
  }

  const tollFee = 10;
  const fare = Number(curruntVehicle.fare)
  const total = Math.round(fare + (fare * 0.18) + tollFee)

  const findIcon = {
    'Airbags': <MdAirlineSeatReclineExtra />,
    'Power Steering': <GiSteeringWheel />,
    'Airconditioning in Car': <FaFan />,
    'Radio': <MdRadio />,
    'Audio input': <GiSpeaker />,
    'Bluetooth': <MdBluetoothAudio />,
    'GPS': <MdOutlineGpsFixed />,
    'Winter tyres': <GiCarWheel />,
    'Parking Sensor': <CiParking1 />,
    'Reversing camera': <MdOutlineLinkedCamera />
  }

  const reviewsData = [
    {
      name: 'Jackson',
      img: 'person-one.jpg',
      detail: "From the moment I accessed their user-friendly app, I was impressed by the simplicity and efficiency of the booking process. Within minutes, I had a confirmed ride to my destination. The convenience of being able to book a car at any time, whether it's for an immediate need or a future trip."
    },
    {
      name: 'Connor',
      img: 'person-two.jpg',
      detail: "The quality of the vehicles provided by BookMyCarNow is outstanding. Each ride I've booked has been in a clean, well-maintained car with a professional and courteous driver. Whether it's a short ride across town or a longer journey to the airport, their fleet has always exceeded my expectations."
    },
    {
      name: 'Sophia',
      img: 'person-three.jpeg',
      detail: "Time is of the essence, especially when traveling. With BookMyCarNow, I never have to worry about late arrivals. Their drivers are consistently on time, if not early, which has saved me from the stress of rushing to catch flights or meetings."
    },
  ]

  const handleBooking = () => {
    if (user === null) {
      showLogin();
    } else {
      navigate('/book-now')
    }
  }

  return (
    <>
      <MainHeader />

      <div className="main-container">
        <div className="container">
          <div className="car-detail">
            <div className="main-car">
              <div className="car-heading">
                <h2>{curruntVehicle.car_type}</h2>
                <div className="tags">
                  <div className="rating d-flex gap-3">
                    <FaStar
                      color='#ffc107'
                      size={25}
                    />
                    <p>4.{rating}/5</p>
                  </div>
                  <button className="fav-btn">
                    <FaRegHeart
                      color='#fd4452'
                      size={25}
                    /> &nbsp;
                    <span>Add to Favorites</span>
                  </button>
                </div>
              </div>
              <div className="car-imgs">
                <div className="col-md-6 w-100">
                  <Carousel className="w-auto">
                    {curruntVehicle.car_pics && curruntVehicle.car_pics.map((el, i) => (
                      <Carousel.Item key={i}>
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/Cars/${el}`}
                          alt="Car-img"
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>

            <div className="main-feature">
              <div className="box">
                <div className="icon">
                  <MdAirlineSeatReclineExtra
                    color='#fd4452'
                    size={30}
                  />
                </div>
                <p>{curruntVehicle.passenger} Seats <br /> <span>with seatbelts</span></p>
              </div>
              <div className="box">
                <div className="icon">
                  <FaGasPump
                    color='#fd4452'
                    size={30}
                  />
                </div>
                <p>Diesel</p>
              </div>
              <div className="box">
                <div className="icon">
                  <MdOutlineLuggage
                    color='#fd4452'
                    size={30}
                  />
                </div>
                <p>{curruntVehicle.luggage} Luggage </p>
              </div>
            </div>
            <hr />
            <div className="owner-detail">
              <h4 className="fw-bold">Reviews</h4>
              <Carousel className="w-auto">
                {reviewsData && reviewsData.map((el, i) => (

                  <Carousel.Item key={i}>
                    <div className="user-det">
                      <div className="owner-img">
                        <img className='rounded-circle' src={`/images/${el.img}`} />
                        <h5>{el.name}</h5>
                      </div>
                      <div className="personal-review">
                        <div className="star">
                          <FaStar
                            color='#ffc107'
                            size={25}
                          />
                          <FaStar
                            color='#ffc107'
                            size={25}
                          />
                          <FaStar
                            color='#ffc107'
                            size={25}
                          />
                          <FaStar
                            color='#ffc107'
                            size={25}
                          />
                          <FaStarHalf
                            color='#ffc107'
                            size={25}
                          />
                        </div>
                        <p className='mt-3'>{el.detail}</p>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>

            </div>
            <hr />

            <div className="detail-description">
              <div className="dis">
                <div className="heading-side">
                  <h5 className="fw-bold">
                    Description
                  </h5>
                </div>
                <div className="detail-side">
                  <p>{curruntVehicle.description}</p>
                </div>
              </div>

              <div className="dis hello mt-3">
                <div className="heading-side">
                  <h5 className="fw-bold">
                  </h5>
                </div>
                <div className="detail-side ">
                  <div className="car-feat">
                    <div className="box d-flex gap-2">
                      <div className="icon sp">
                        <GiGearStickPattern
                          size={20}
                          className='mt-1'
                        />
                      </div>
                      <div className='icon-name fs-5'>Manual</div>
                    </div>

                    <div className="box d-flex gap-2">
                      <div className="icon">
                        <MdOutlineHealthAndSafety
                          size={20}
                          className='mt-1'
                        />
                      </div>
                      <div className='icon-name fs-5'>Better Safety</div>
                    </div>

                    <div className="box d-flex gap-2">
                      <div className="icon">
                        <IoBuild
                          size={20}
                          className='mt-1'
                        />
                      </div>
                      <div className='icon-name fs-5'>Self-built</div>
                    </div>

                    <div className="box d-flex gap-2">
                      <div className="icon">
                        <CiCreditCard2
                          size={20}
                          className='mt-1'
                        />
                      </div>
                      <div className='icon-name fs-5'>Driving licence B
                      </div>
                    </div>

                    <div className="box d-flex gap-2">
                      <div className="icon">
                        <GiLindenLeaf
                          size={20}
                          className='mt-1'
                        />
                      </div>
                      <div className='icon-name fs-5'>Euro 6</div>
                    </div>
                  </div>
                  <hr />
                  <div className="car-size">
                    <p>Car length and height <b>450 cm x 470 cm</b> </p>
                  </div>
                </div>
              </div>

              <hr />

              <div className="dis">
                <div className="heading-side">
                  <h5 className="fw-bold">
                    Car Layout
                  </h5>
                </div>
                <div className="detail-side">
                  <div className="layout-detail">
                    <div className="img">
                      <p>Layout One</p>
                      <img src="images/layout-one.png" alt="" />
                    </div>
                    <div className="img">
                      <p>Layout Two</p>
                      <img src="images/layout-two.jpeg" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="dis">
                <div className="heading-side">
                  <h5 className="fw-bold">
                    Car Features
                  </h5>
                </div>
                <div className="detail-side">
                  <div className="feature-detail">
                    {car_features.map((item, i) => (
                      <div className="box d-flex gap-2">
                        <div className="icon icon-size">
                          {findIcon[`${item}`]}
                        </div>
                        <div className='icon-name fs-5 mt-2'>{item}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <hr />
              <div className="dis">
                <div className="heading-side">
                  <h5 className="fw-bold">
                    Pricing
                  </h5>
                </div>
                <div className="detail-side">
                  <div className="car-rent">
                    <table class="table border">
                      <tbody>
                        <tr>
                          <th>Car Rent Pre Mile</th>
                          <td className='text-end'>$ {curruntVehicle.our_fee} /mile</td>
                        </tr>
                        {curruntVehicle.isLuxury === true ?
                          <tr>
                            <th>Luxury Service</th>
                            <td className='text-end'>18 %</td>
                          </tr> : ""
                        }
                        <tr>
                          <th>Toll Fee</th>
                          <td className='text-end'>As per location</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="pricing-detail border rounded p-3">
            <div className="loactions">
              <h5 className='fw-bold'>PickUp Location</h5>
              <p>{searchVehicle.pickUpAddress}</p>
              <h5 className='fw-bold'>DropOff Location</h5>
              <p>{searchVehicle.dropOffAddress}</p>
            </div>
            <hr />
            <div className="date-time">
              <h5 className='fw-bold'>PickUp Date / Time</h5>
              <div className="detail d-flex gap-2">
                <p>{searchVehicle.pickUpDate}</p>
                <p className='hh'>{searchVehicle.pickUpTime}</p>
              </div>
            </div>
            <div className="booking-rent">
              <table class="table border">
                <tbody>
                  <tr>
                    <th>Fare</th>
                    <td className='text-end'>$ {Math.round(curruntVehicle.fare)}</td>
                  </tr>
                  {curruntVehicle.isLuxury === true ?
                    <tr>
                      <th>Luxury Service</th>
                      <td className='text-end'>$ {Math.round(curruntVehicle.fare * 0.18)}</td>
                    </tr> : ""
                  }
                  <tr>
                    <th>Toll Fee</th>
                    <td className='text-end'>$ {tollFee}</td>
                  </tr>
                  <tr>
                    <th>Total Amount</th>
                    <td className='text-end'>$ {total}</td>
                  </tr>
                </tbody>
              </table>
              <button id='booking-btn' className='text-light btn btn-danger w-100'
                onClick={handleBooking}
              >
                Proceed to Booking
              </button>

              <p className='text-primary mt-2 text-center'
                role="button"
                onClick={showModal}
              >
                View the Booking process
              </p>
              <hr />

              <div className="policy">
                <div className="one d-flex gap-2">
                  <TiTick
                    size={25}
                    color='green'
                  />
                  <p>Make a request <span> without obligation</span></p>
                </div>
                <div className="one d-flex gap-2">
                  <TiTick
                    size={25}
                    color='green'
                  />
                  <p>Working towards <span>greener road trips</span></p>
                </div>
                <div className="one d-flex gap-2">
                  <TiTick
                    size={25}
                    color='green'
                  />
                  <p><span>24/7</span> roadside assistance</p>
                </div>
                <div className="one d-flex gap-2">
                  <TiTick
                    size={25}
                    color='green'
                  />
                  <p><span>Easy payment</span> with all major debit and credit cards</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={isLoginOpen}
        onCancel={CancelLogin}
        footer={false}
        width={500}
      >
        <LoginPopUp />
      </Modal>

      <Modal
        style={{
          'margin': '-50px auto'
        }}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        width={800}
      >
        <BookingProcessDialog />
      </Modal>
      <MainFooter />
    </>
  )
}

export default CarDetail