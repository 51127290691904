import React from "react";
import { MainHeader } from "../components/MainHeader";
import MainFooter from "../components/MainFooter";
import Checkout from "../Sections/Checkout"
const BookNow = () => {
  return (
    <>
      <MainHeader />
      <Checkout />
      <MainFooter />
    </>
  );
};

export default BookNow;
